input,
textarea {
    font-size: 16px;
}
.toast-top-center {
    position: fixed; /* Ensures it stays in place even during scrolling */
    top: 20px; /* Distance from the top of the viewport */
    left: 50%; /* Center the toast horizontally */
    transform: translateX(-50%); /* Adjust for center alignment */
    z-index: 1050; /* Ensure it appears above other elements */
    padding: 10px;
}

.toast {
    margin: 5px 15px 5px 15px !important;
    border-radius: 8px;
}

.toast-success {
    background-color: #28a745 !important;
    color: #fff !important;
    padding: 5px 15px 5px 15px !important;
    border-radius: 8px;
}

.toast-error {
    background-color: #dc3545 !important;
    color: #fff !important;
    padding: 5px 15px 5px 15px !important;
    border-radius: 8px;
}

.toast-warning {
    background-color: #ffc107 !important;
    color: #212529 !important;
    padding: 5px 15px 5px 15px !important;
    border-radius: 8px;
}

.toast-info {
    background-color: #17a2b8 !important;
    color: #fff !important;
    padding: 5px 15px 5px 15px !important;
    border-radius: 8px;
}

.toast-title {
    font-weight: bold;
    font-size: 16px;
}

.toast-message {
    font-size: 14px;
}



body{
    font-family: Arial, sans-serif;
}


































































[data-icon]:before {
    font-family: "anticon" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.anticon {
    line-height: 0;
    vertical-align: -0.125em;
}

.anticon:before {
    font-family: "anticon" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.anticon-account-book:before {
    content: "\61";
}

.anticon-alert:before {
    content: "\62";
}

.anticon-alibaba:before {
    content: "\63";
}

.anticon-align-center:before {
    content: "\64";
}

.anticon-align-left:before {
    content: "\65";
}

.anticon-align-right:before {
    content: "\66";
}

.anticon-alipay:before {
    content: "\67";
}

.anticon-alipay-circle:before {
    content: "\68";
}

.anticon-aliwangwang:before {
    content: "\69";
}

.anticon-aliyun:before {
    content: "\6a";
}

.anticon-amazon:before {
    content: "\6b";
}

.anticon-android:before {
    content: "\6c";
}

.anticon-ant-cloud:before {
    content: "\6d";
}

.anticon-ant-design:before {
    content: "\6e";
}

.anticon-apartment:before {
    content: "\6f";
}

.anticon-api:before {
    content: "\70";
}

.anticon-apple:before {
    content: "\71";
}

.anticon-appstore:before {
    content: "\72";
}

.anticon-area-chart:before {
    content: "\73";
}

.anticon-arrow-down:before {
    content: "\74";
}

.anticon-arrow-left:before {
    content: "\75";
}

.anticon-arrow-right:before {
    content: "\76";
}

.anticon-arrow-up:before {
    content: "\77";
}

.anticon-arrows-alt:before {
    content: "\78";
}

.anticon-audio:before {
    content: "\79";
}

.anticon-audit:before {
    content: "\7a";
}

.anticon-backward:before {
    content: "\41";
}

.anticon-bank:before {
    content: "\42";
}

.anticon-bar-chart:before {
    content: "\43";
}

.anticon-barcode:before {
    content: "\44";
}

.anticon-bars:before {
    content: "\45";
}

.anticon-behance:before {
    content: "\46";
}

.anticon-behance-square:before {
    content: "\47";
}

.anticon-bell:before {
    content: "\48";
}

.anticon-bg-colors:before {
    content: "\49";
}

.anticon-block:before {
    content: "\4a";
}

.anticon-bold:before {
    content: "\4b";
}

.anticon-book:before {
    content: "\4c";
}

.anticon-border:before {
    content: "\4d";
}

.anticon-border-bottom:before {
    content: "\4e";
}

.anticon-border-horizontal:before {
    content: "\4f";
}

.anticon-border-inner:before {
    content: "\50";
}

.anticon-border-left:before {
    content: "\51";
}

.anticon-border-outer:before {
    content: "\52";
}

.anticon-border-right:before {
    content: "\53";
}

.anticon-border-top:before {
    content: "\54";
}

.anticon-border-verticle:before {
    content: "\55";
}

.anticon-box-plot:before {
    content: "\56";
}

.anticon-branches:before {
    content: "\57";
}

.anticon-build:before {
    content: "\58";
}

.anticon-bulb:before {
    content: "\59";
}

.anticon-calculator:before {
    content: "\5a";
}

.anticon-calendar:before {
    content: "\30";
}

.anticon-camera:before {
    content: "\31";
}

.anticon-car:before {
    content: "\32";
}

.anticon-caret-down:before {
    content: "\33";
}

.anticon-caret-left:before {
    content: "\34";
}

.anticon-caret-right:before {
    content: "\35";
}

.anticon-caret-up:before {
    content: "\36";
}

.anticon-carry-out:before {
    content: "\37";
}

.anticon-check:before {
    content: "\38";
}

.anticon-check-circle:before {
    content: "\39";
}

.anticon-check-square:before {
    content: "\21";
}

.anticon-chrome:before {
    content: "\22";
}

.anticon-ci:before {
    content: "\23";
}

.anticon-clock-circle:before {
    content: "\24";
}

.anticon-close:before {
    content: "\25";
}

.anticon-close-circle:before {
    content: "\26";
}

.anticon-close-square:before {
    content: "\27";
}

.anticon-cloud:before {
    content: "\28";
}

.anticon-cloud-download:before {
    content: "\29";
}

.anticon-cloud-server:before {
    content: "\2a";
}

.anticon-cloud-sync:before {
    content: "\2b";
}

.anticon-cloud-upload:before {
    content: "\2c";
}

.anticon-cluster:before {
    content: "\2d";
}

.anticon-code:before {
    content: "\2e";
}

.anticon-code-sandbox:before {
    content: "\2f";
}

.anticon-codepen:before {
    content: "\3a";
}

.anticon-codepen-circle:before {
    content: "\3b";
}

.anticon-coffee:before {
    content: "\3c";
}

.anticon-colum-height:before {
    content: "\3d";
}

.anticon-column-width:before {
    content: "\3e";
}

.anticon-compass:before {
    content: "\3f";
}

.anticon-contacts:before {
    content: "\40";
}

.anticon-container:before {
    content: "\5b";
}

.anticon-control:before {
    content: "\5d";
}

.anticon-copy:before {
    content: "\5e";
}

.anticon-copyright:before {
    content: "\5f";
}

.anticon-credit-card:before {
    content: "\60";
}

.anticon-crown:before {
    content: "\7b";
}

.anticon-customer-service:before {
    content: "\7c";
}

.anticon-dash:before {
    content: "\7d";
}

.anticon-dashboard:before {
    content: "\7e";
}

.anticon-database:before {
    content: "\5c";
}

.anticon-delete:before {
    content: "\e000";
}

.anticon-deployment-unit:before {
    content: "\e001";
}

.anticon-desktop:before {
    content: "\e002";
}

.anticon-diff:before {
    content: "\e003";
}

.anticon-dingding:before {
    content: "\e004";
}

.anticon-disconnect:before {
    content: "\e005";
}

.anticon-dislike:before {
    content: "\e006";
}

.anticon-dollar:before {
    content: "\e007";
}

.anticon-dot-chart:before {
    content: "\e008";
}

.anticon-double-left:before {
    content: "\e009";
}

.anticon-double-right:before {
    content: "\e00a";
}

.anticon-down:before {
    content: "\e00b";
}

.anticon-down-circle:before {
    content: "\e00c";
}

.anticon-down-square:before {
    content: "\e00d";
}

.anticon-download:before {
    content: "\e00e";
}

.anticon-drag:before {
    content: "\e00f";
}

.anticon-dribbble:before {
    content: "\e010";
}

.anticon-dribbble-square:before {
    content: "\e011";
}

.anticon-dropbox:before {
    content: "\e012";
}

.anticon-edit:before {
    content: "\e013";
}

.anticon-ellipsis:before {
    content: "\e014";
}

.anticon-enter:before {
    content: "\e015";
}

.anticon-environment:before {
    content: "\e016";
}

.anticon-euro:before {
    content: "\e017";
}

.anticon-exception:before {
    content: "\e018";
}

.anticon-exclamation:before {
    content: "\e019";
}

.anticon-exclamation-circle:before {
    content: "\e01a";
}

.anticon-experiment:before {
    content: "\e01b";
}

.anticon-export:before {
    content: "\e01c";
}

.anticon-eye:before {
    content: "\e01d";
}

.anticon-eye-invisible:before {
    content: "\e01e";
}

.anticon-facebook:before {
    content: "\e01f";
}

.anticon-fall:before {
    content: "\e020";
}

.anticon-fast-backward:before {
    content: "\e021";
}

.anticon-fast-forward:before {
    content: "\e022";
}

.anticon-file:before {
    content: "\e023";
}

.anticon-file-add:before {
    content: "\e024";
}

.anticon-file-done:before {
    content: "\e025";
}

.anticon-file-excel:before {
    content: "\e026";
}

.anticon-file-exclamation:before {
    content: "\e027";
}

.anticon-file-image:before {
    content: "\e028";
}

.anticon-file-jpg:before {
    content: "\e029";
}

.anticon-file-markdown:before {
    content: "\e02a";
}

.anticon-file-pdf:before {
    content: "\e02b";
}

.anticon-file-ppt:before {
    content: "\e02c";
}

.anticon-file-protect:before {
    content: "\e02d";
}

.anticon-file-search:before {
    content: "\e02e";
}

.anticon-file-sync:before {
    content: "\e02f";
}

.anticon-file-text:before {
    content: "\e030";
}

.anticon-file-unknown:before {
    content: "\e031";
}

.anticon-file-word:before {
    content: "\e032";
}

.anticon-file-zip:before {
    content: "\e033";
}

.anticon-filter:before {
    content: "\e034";
}

.anticon-fire:before {
    content: "\e035";
}

.anticon-flag:before {
    content: "\e036";
}

.anticon-folder:before {
    content: "\e037";
}

.anticon-folder-add:before {
    content: "\e038";
}

.anticon-folder-open:before {
    content: "\e039";
}

.anticon-font-colors:before {
    content: "\e03a";
}

.anticon-font-size:before {
    content: "\e03b";
}

.anticon-fork:before {
    content: "\e03c";
}

.anticon-form:before {
    content: "\e03d";
}

.anticon-forward:before {
    content: "\e03e";
}

.anticon-frown:before {
    content: "\e03f";
}

.anticon-fullscreen:before {
    content: "\e040";
}

.anticon-fullscreen-exit:before {
    content: "\e041";
}

.anticon-fund:before {
    content: "\e042";
}

.anticon-funnel-plot:before {
    content: "\e043";
}

.anticon-gateway:before {
    content: "\e044";
}

.anticon-gift:before {
    content: "\e045";
}

.anticon-github:before {
    content: "\e046";
}

.anticon-gitlab:before {
    content: "\e047";
}

.anticon-global:before {
    content: "\e048";
}

.anticon-gold:before {
    content: "\e049";
}

.anticon-google:before {
    content: "\e04a";
}

.anticon-google-plus:before {
    content: "\e04b";
}

.anticon-hdd:before {
    content: "\e04c";
}

.anticon-heart:before {
    content: "\e04d";
}

.anticon-heat-map:before {
    content: "\e04e";
}

.anticon-highlight:before {
    content: "\e04f";
}

.anticon-home:before {
    content: "\e050";
}

.anticon-hourglass:before {
    content: "\e051";
}

.anticon-html5:before {
    content: "\e052";
}

.anticon-idcard:before {
    content: "\e053";
}

.anticon-ie:before {
    content: "\e054";
}

.anticon-import:before {
    content: "\e055";
}

.anticon-inbox:before {
    content: "\e056";
}

.anticon-info:before {
    content: "\e057";
}

.anticon-info-circle:before {
    content: "\e058";
}

.anticon-instagram:before {
    content: "\e059";
}

.anticon-insurance:before {
    content: "\e05a";
}

.anticon-interation:before {
    content: "\e05b";
}

.anticon-issues-close:before {
    content: "\e05c";
}

.anticon-italic:before {
    content: "\e05d";
}

.anticon-key:before {
    content: "\e05e";
}

.anticon-laptop:before {
    content: "\e05f";
}

.anticon-layout:before {
    content: "\e060";
}

.anticon-left:before {
    content: "\e061";
}

.anticon-left-circle:before {
    content: "\e062";
}

.anticon-left-square:before {
    content: "\e063";
}

.anticon-like:before {
    content: "\e064";
}

.anticon-line:before {
    content: "\e065";
}

.anticon-line-chart:before {
    content: "\e066";
}

.anticon-line-height:before {
    content: "\e067";
}

.anticon-link:before {
    content: "\e068";
}

.anticon-linkedin:before {
    content: "\e069";
}

.anticon-loading:before {
    content: "\e06a";
    display: inline-block;
    animation: 1s linear infinite loadingCircle;
}

.anticon-loading-3-quarters:before {
    content: "\e06b";
}

.anticon-lock:before {
    content: "\e06c";
}

.anticon-login:before {
    content: "\e06d";
}

.anticon-logout:before {
    content: "\e06e";
}

.anticon-mail:before {
    content: "\e06f";
}

.anticon-man:before {
    content: "\e070";
}

.anticon-medicine-box:before {
    content: "\e071";
}

.anticon-medium:before {
    content: "\e072";
}

.anticon-medium-workmark:before {
    content: "\e073";
}

.anticon-meh:before {
    content: "\e074";
}

.anticon-menu:before {
    content: "\e075";
}

.anticon-menu-fold:before {
    content: "\e076";
}

.anticon-menu-unfold:before {
    content: "\e077";
}

.anticon-message:before {
    content: "\e078";
}

.anticon-minus:before {
    content: "\e079";
}

.anticon-minus-circle:before {
    content: "\e07a";
}

.anticon-minus-square:before {
    content: "\e07b";
}

.anticon-mobile:before {
    content: "\e07c";
}

.anticon-money-collect:before {
    content: "\e07d";
}

.anticon-monitor:before {
    content: "\e07e";
}

.anticon-mr:before {
    content: "\e07f";
}

.anticon-notification:before {
    content: "\e080";
}

.anticon-number:before {
    content: "\e081";
}

.anticon-ordered-list:before {
    content: "\e082";
}

.anticon-paper-clip:before {
    content: "\e083";
}

.anticon-pause:before {
    content: "\e084";
}

.anticon-pause-circle:before {
    content: "\e085";
}

.anticon-pay-circle:before {
    content: "\e086";
}

.anticon-percentage:before {
    content: "\e087";
}

.anticon-phone:before {
    content: "\e088";
}

.anticon-pic-center:before {
    content: "\e089";
}

.anticon-pic-left:before {
    content: "\e08a";
}

.anticon-pic-right:before {
    content: "\e08b";
}

.anticon-picture:before {
    content: "\e08c";
}

.anticon-pie-chart:before {
    content: "\e08d";
}

.anticon-play-circle:before {
    content: "\e08e";
}

.anticon-play-square:before {
    content: "\e08f";
}

.anticon-plus:before {
    content: "\e090";
}

.anticon-plus-circle:before {
    content: "\e091";
}

.anticon-plus-square:before {
    content: "\e092";
}

.anticon-pound:before {
    content: "\e093";
}

.anticon-poweroff:before {
    content: "\e094";
}

.anticon-printer:before {
    content: "\e095";
}

.anticon-profile:before {
    content: "\e096";
}

.anticon-project:before {
    content: "\e097";
}

.anticon-property-safety:before {
    content: "\e098";
}

.anticon-pushpin:before {
    content: "\e099";
}

.anticon-qq:before {
    content: "\e09a";
}

.anticon-qrcode:before {
    content: "\e09b";
}

.anticon-question:before {
    content: "\e09c";
}

.anticon-question-circle:before {
    content: "\e09d";
}

.anticon-radar-chart:before {
    content: "\e09e";
}

.anticon-radius-bottomleft:before {
    content: "\e09f";
}

.anticon-radius-bottomright:before {
    content: "\e0a0";
}

.anticon-radius-setting:before {
    content: "\e0a1";
}

.anticon-radius-upleft:before {
    content: "\e0a2";
}

.anticon-radius-upright:before {
    content: "\e0a3";
}

.anticon-read:before {
    content: "\e0a4";
}

.anticon-reconciliation:before {
    content: "\e0a5";
}

.anticon-red-envelope:before {
    content: "\e0a6";
}

.anticon-reddit:before {
    content: "\e0a7";
}

.anticon-redo:before {
    content: "\e0a8";
}

.anticon-reload:before {
    content: "\e0a9";
}

.anticon-reload-time:before {
    content: "\e0aa";
}

.anticon-rest:before {
    content: "\e0ab";
}

.anticon-retweet:before {
    content: "\e0ac";
}

.anticon-right:before {
    content: "\e0ad";
}

.anticon-right-circle:before {
    content: "\e0ae";
}

.anticon-right-square:before {
    content: "\e0af";
}

.anticon-rise:before {
    content: "\e0b0";
}

.anticon-robot:before {
    content: "\e0b1";
}

.anticon-rocket:before {
    content: "\e0b2";
}

.anticon-rollback:before {
    content: "\e0b3";
}

.anticon-safety:before {
    content: "\e0b4";
}

.anticon-safety-certificate:before {
    content: "\e0b5";
}

.anticon-save:before {
    content: "\e0b6";
}

.anticon-scan:before {
    content: "\e0b7";
}

.anticon-schedule:before {
    content: "\e0b8";
}

.anticon-scissor:before {
    content: "\e0b9";
}

.anticon-search:before {
    content: "\e0ba";
}

.anticon-security-scan:before {
    content: "\e0bb";
}

.anticon-select:before {
    content: "\e0bc";
}

.anticon-setting:before {
    content: "\e0bd";
}

.anticon-shake:before {
    content: "\e0be";
}

.anticon-share-alt:before {
    content: "\e0bf";
}

.anticon-shop:before {
    content: "\e0c0";
}

.anticon-shopping:before {
    content: "\e0c1";
}

.anticon-shopping-cart:before {
    content: "\e0c2";
}

.anticon-shrink:before {
    content: "\e0c3";
}

.anticon-sketch:before {
    content: "\e0c4";
}

.anticon-skin:before {
    content: "\e0c5";
}

.anticon-skype:before {
    content: "\e0c6";
}

.anticon-slack:before {
    content: "\e0c7";
}

.anticon-slack-square:before {
    content: "\e0c8";
}

.anticon-sliders:before {
    content: "\e0c9";
}

.anticon-small-dash:before {
    content: "\e0ca";
}

.anticon-smile:before {
    content: "\e0cb";
}

.anticon-snippets:before {
    content: "\e0cc";
}

.anticon-solution:before {
    content: "\e0cd";
}

.anticon-sort-ascending:before {
    content: "\e0ce";
}

.anticon-sort-descending:before {
    content: "\e0cf";
}

.anticon-sound:before {
    content: "\e0d0";
}

.anticon-star:before {
    content: "\e0d1";
}

.anticon-step-backward:before {
    content: "\e0d2";
}

.anticon-step-forward:before {
    content: "\e0d3";
}

.anticon-stock:before {
    content: "\e0d4";
}

.anticon-stop:before {
    content: "\e0d5";
}

.anticon-strikethrough:before {
    content: "\e0d6";
}

.anticon-swap:before {
    content: "\e0d7";
}

.anticon-swap-left:before {
    content: "\e0d8";
}

.anticon-swap-right:before {
    content: "\e0d9";
}

.anticon-switcher:before {
    content: "\e0da";
}

.anticon-sync:before {
    content: "\e0db";
}

.anticon-table:before {
    content: "\e0dc";
}

.anticon-tablet:before {
    content: "\e0dd";
}

.anticon-tag:before {
    content: "\e0de";
}

.anticon-tags:before {
    content: "\e0df";
}

.anticon-taobao:before {
    content: "\e0e0";
}

.anticon-taobao-circle:before {
    content: "\e0e1";
}

.anticon-team:before {
    content: "\e0e2";
}

.anticon-thunderbolt:before {
    content: "\e0e3";
}

.anticon-to-top:before {
    content: "\e0e4";
}

.anticon-tool:before {
    content: "\e0e5";
}

.anticon-trademark:before {
    content: "\e0e6";
}

.anticon-transaction:before {
    content: "\e0e7";
}

.anticon-trophy:before {
    content: "\e0e8";
}

.anticon-twitter:before {
    content: "\e0e9";
}

.anticon-underline:before {
    content: "\e0ea";
}

.anticon-undo:before {
    content: "\e0eb";
}

.anticon-unlock:before {
    content: "\e0ec";
}

.anticon-unordered-list:before {
    content: "\e0ed";
}

.anticon-up:before {
    content: "\e0ee";
}

.anticon-up-circle:before {
    content: "\e0ef";
}

.anticon-up-square:before {
    content: "\e0f0";
}

.anticon-upload:before {
    content: "\e0f1";
}

.anticon-usb:before {
    content: "\e0f2";
}

.anticon-user:before {
    content: "\e0f3";
}

.anticon-user-add:before {
    content: "\e0f4";
}

.anticon-user-delete:before {
    content: "\e0f5";
}

.anticon-usergroup-add:before {
    content: "\e0f6";
}

.anticon-usergroup-delete:before {
    content: "\e0f7";
}

.anticon-vertical-align-bottom:before {
    content: "\e0f8";
}

.anticon-vertical-align-middle:before {
    content: "\e0f9";
}

.anticon-vertical-align-top:before {
    content: "\e0fa";
}

.anticon-vertical-left:before {
    content: "\e0fb";
}

.anticon-vertical-right:before {
    content: "\e0fc";
}

.anticon-video-camera:before {
    content: "\e0fd";
}

.anticon-wallet:before {
    content: "\e0fe";
}

.anticon-warning:before {
    content: "\e0ff";
}

.anticon-wechat:before {
    content: "\e100";
}

.anticon-weibo:before {
    content: "\e101";
}

.anticon-weibo-circle:before {
    content: "\e102";
}

.anticon-weibo-square:before {
    content: "\e103";
}

.anticon-wifi:before {
    content: "\e104";
}

.anticon-windows:before {
    content: "\e105";
}

.anticon-woman:before {
    content: "\e106";
}

.anticon-yahoo:before {
    content: "\e107";
}

.anticon-youtube:before {
    content: "\e108";
}

.anticon-yuque:before {
    content: "\e109";
}

.anticon-zhihu:before {
    content: "\e10a";
}

.anticon-zoom-in:before {
    content: "\e10b";
}

.anticon-zoom-out:before {
    content: "\e10c";
}

.anticon-info-o:before {
    content: "\e10d";
}

.anticon-exclamation-o:before {
    content: "\e10e";
}

.anticon-close-o:before {
    content: "\e10f";
}

.anticon-check-o:before {
    content: "\e110";
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: sofia pro;
    font-weight: 400;
    color: rgba(0, 18, 25, 0.7);
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

input {
    outline: none;
}

button {
    border: 0;
    outline: none;
    transition: 0.3s;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: sofia pro;
    font-weight: 500;
}

a {
    text-decoration: none;
    transition: 0.2s;
}

ul {
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

/* colors */
.black-color {
    color: #001219;
}

.blue-color {
    color: #003049;
}

.primary-color {
    color: #e73667;
}

.white-color {
    color: #ffffff;
}

.yellow-color {
    color: #ffc300;
}

.green-color {
    color: #2a9d8f;
}

.red-color {
    color: #ef476f;
}

/* Backgrounds */
.black-bg {
    background: #001219;
}

.blue-bg {
    background: #003049;
}

.primary-bg {
    background: #e73667;
}

.gray-bg {
    background: #d2d3d8;
}

.light-blue-bg {
    background: #bee3db;
}

.dark-blue-bg {
    background: #020d24;
}

.site-overlay {
    background-size: cover !important;
    position: relative;
    z-index: 1;
}

.site-overlay::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #003049;
    content: "";
    z-index: -1;
    opacity: 0.6;
}

.primary-overlay {
    background-size: cover !important;
    position: relative;
    z-index: 1;
}

.primary-overlay::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #e73667;
    content: "";
    z-index: -1;
    opacity: 0.3;
}

.grad-overlay {
    background-size: cover !important;
    position: relative;
    z-index: 1;
}

.grad-overlay::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        95deg,
        #059dff 15%,
        #6549d5 45%,
        #e33fa1 75%,
        #fb5343
    ) 95%/200% 100%;
    content: "";
    z-index: -1;
    opacity: 0.3;
}

/* Section Styles */
.section-style {
    padding: 80px 0;
    position: relative;
}

.section-style-2 {
    padding: 80px 0 50px 0;
    position: relative;
}

.section-style-3 {
    padding: 80px 0 30px 0;
    position: relative;
}

.section-gap {
    padding: 30px 0 30px 0;
    position: relative;
}

.section-title {
    margin-bottom: 60px;
}

.section-title h4 {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #2a9d8f;
}

.section-title h2 {
    font-size: 42px;
    font-weight: 700;
    background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.page-head {
    padding: 170px 0 80px 0;
}

.page-head h2 {
    margin-bottom: 0;
    font-size: 34px;
    color: #ffffff;
}

.site-btn {
    display: inline-block;
    padding: 13px 27px;
    border-radius: 50px;
    color: #001219;
    background: #ffffff;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
    box-shadow: 0px 0px 2px #00304966;
}

.site-btn:hover {
    background: #e73667;
    color: #ffffff;
    transform: translate3d(0, -2px, 0);
}

.site-btn:focus {
    background: #e73667;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.site-btn:active {
    background: #e73667;
    color: #ffffff;
    border: 0;
    box-shadow: none;
}

.site-btn i {
    margin-right: 4px;
}

.site-btn-sm {
    display: inline-block;
    padding: 11px 20px;
    border-radius: 50px;
    color: #001219;
    background: #ffffff;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}

.site-btn-sm:hover {
    background: #e73667;
    color: #ffffff;
    transform: translate3d(0, -2px, 0);
}

.site-btn-sm i {
    margin-right: 5px;
}

.site-btn-round {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    text-decoration: none;
    color: #ffffff;
    background: #e73667;
}

.site-btn-round:hover {
    background: #e73667;
    color: #ffffff;
}

.primary-btn {
    color: #ffffff;
    background: #e73667;
}

.primary-btn:hover {
    background: #ffffff;
    color: #001219;
}

.blue-btn {
    background: #e73667;
    color: #ffffff;
}

.blue-btn:hover {
    background: #003049;
    color: #ffffff;
}

.grad-btn {
    background: linear-gradient(90deg, #e52e71, #ff8a00);
    color: #ffffff;
}

.red-btn {
    background: #ef476f;
    color: #ffffff;
}

.red-btn:hover {
    background: #003049;
    color: #ffffff;
}

.btn-link {
    color: rgba(0, 18, 25, 0.7);
    position: relative;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
}

.btn-link::after {
    position: absolute;
    content: "";
    height: 2px;
    background: #e73667;
    width: 0px;
    transition: 0.3s;
    left: 0;
    bottom: 0px;
}

.btn-link:hover {
    color: #e73667;
}

.btn-link:hover::after {
    width: 100%;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
    width: 0px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #e73667;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #003049;
}

/* toTop Button */
#scrollUp {
    background: rgba(231, 54, 103, 0.3);
    color: #e73667;
    right: 30px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
}

#scrollUp:hover {
    background: #e73667;
    color: #ffffff;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: initial;
    }
}

.centered {
    text-align: center;
}

.coin-left {
    position: relative;
}

.coin-left::before {
    height: 225px;
    width: 200px;
    position: absolute;
    content: "";
    left: 100px;
    top: 100px;
    /* background: url("../images/coin.png") repeat; */
    z-index: -1;
    opacity: 0.3;
}

@media (max-width: 991px) {
    .coin-left::before {
        display: none;
    }
}

.coin-right {
    position: relative;
}

.coin-right::after {
    height: 225px;
    width: 200px;
    position: absolute;
    content: "";
    right: 100px;
    top: 100px;
    /* background: url("../images/coin.png") repeat; */
    z-index: -1;
    opacity: 0.3;
}

@media (max-width: 991px) {
    .coin-right::after {
        display: none;
    }
}

/* Custom css for Dark dropdown */
.dropdown-menu-dark {
    background: #001219;
    padding-top: 5px;
    padding-bottom: 0;
    border-radius: 4px;
    border: 0;
    min-width: 200px;
}

.dropdown-menu-dark li {
    margin-top: 10px;
}

.dropdown-menu-dark li .dropdown-item {
    padding: 8px 15px;
    border-radius: 4px;
}

.dropdown-menu-dark li .dropdown-item i {
    margin-right: 5px;
}

.dropdown-menu-dark li .dropdown-item:hover {
    background: #e73667;
    color: #ffffff;
}

/* Site Card */
.site-card {
    box-shadow: 0px 0px 2px rgba(0, 48, 73, 0.4);
    margin-bottom: 30px;
    background: #ffffff;
    border-radius: 4px;
}

.site-card .site-card-header {
    padding: 15px 20px;
    border-bottom: 1px solid #eeeeee;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.site-card .site-card-header .title {
    color: #003049;
    margin-bottom: 0;
    font-size: 18px;
    display: inline-block;
}

@media (max-width: 500px) {
    .site-card .site-card-header .title {
        font-size: 16px;
    }
}

.site-card .site-card-header .title-small {
    color: #003049;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.site-card .site-card-header .card-header-links .card-header-link {
    display: inline-block;
    background: #e73667;
    border-radius: 3px;
    padding: 8px 18px;
    border: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 10px;
}

.site-card .site-card-header .card-header-links .card-header-link:hover {
    background: #003049;
}

.site-card .site-card-header .search {
    font-size: 14px;
}

.site-card .site-card-header .search span {
    margin-right: 5px;
}

.site-card .site-card-header .search input {
    width: 200px;
    height: 27px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0 5px;
    background: transparent;
}

.site-card .site-card-body {
    padding: 20px;
}

.site-card .site-card-body .body-title {
    font-size: 16px;
    margin-bottom: 10px;
}

.site-card .site-card-body .paragraph {
    font-size: 14px;
}

@media (max-width: 400px) {
    .site-card .site-card-body {
        padding: 12px;
    }
}

/* site-alert */
.site-alert {
    color: rgba(0, 18, 25, 0.6);
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 2px #5468ff66;
    margin-bottom: 30px;
    background: rgba(255, 195, 0, 0.1);
    border-radius: 4px;
    padding: 10px 15px;
}

.site-alert .content {
    font-size: 18px;
}

.site-alert .content .icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    background: rgba(255, 195, 0, 0.5);
    color: #001219;
    margin-right: 8px;
    display: inline-block;
}

/* Site Badge */
.site-badge {
    padding: 2px 10px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}

.site-badge.badge-success {
    background: #2a9d8f;
}

.site-badge.badge-failed {
    background: #ef476f;
}

.site-badge.badge-pending {
    background: #ffc300;
    color: rgba(0, 18, 25, 0.7);
}

.input-info-text {
    font-size: 12px;
    color: rgba(239, 71, 111, 0.7);
    margin-top: -18px;
}

/* Image Preview */
.wrap-custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 150px;
    text-align: center;
    border: 1px dashed #d2d3d8;
    border-radius: 8px;
}

.wrap-custom-file input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
    overflow: hidden;
    opacity: 0;
}

.wrap-custom-file label {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: transform 0.4s;
}

.wrap-custom-file label span {
    display: block;
    color: #003049;
    font-size: 14px;
    transition: color 0.4s;
}

.wrap-custom-file label .upload-icon {
    width: 40px;
    margin: 0 auto;
    margin-bottom: 15px;
}

.wrap-custom-file label.file-ok {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.wrap-custom-file label.file-ok span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.3rem;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background-color: rgba(231, 54, 103, 0.5);
}

.wrap-custom-file label.file-ok .upload-icon {
    display: none;
}

.wrap-custom-file label .recomended {
    color: rgba(42, 157, 143, 0.6);
    font-size: 14px;
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: 0;
    left: 50%;
}

/* bat-left */
.bat-left {
    height: 166px;
    width: 300px;
    position: absolute;
    left: 100px;
    top: 100px;
    z-index: -1;
    opacity: 0.5 !important;
}

@media (max-width: 767px) {
    .bat-left {
        right: 0;
    }
}

@media (max-width: 575px) {
    .bat-left {
        display: none;
    }
}

/* bat-right */
.bat-right {
    height: 166px;
    width: 300px;
    position: absolute;
    right: 100px;
    top: 100px;
    z-index: -1;
    opacity: 0.5 !important;
}

@media (max-width: 767px) {
    .bat-right {
        right: 0;
    }
}

@media (max-width: 575px) {
    .bat-right {
        display: none;
    }
}

/* Slider Thumb */
.slider-thumb {
    position: relative;
    z-index: 1;
}

.slider-thumb::before {
    position: absolute;
    content: "";
    left: 10%;
    top: -70px;
    width: 250px;
    height: 250px;
    background: linear-gradient(90deg, #e52e71, #ff8a00);
    border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
    will-change: border-radius, transform, opacity;
    animation: sliderShape 5s linear infinite;
    display: block;
    z-index: -1;
    -webkit-animation: sliderShape 5s linear infinite;
}

@keyframes sliderShape {
    0%,
    100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    34% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }
    50% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}


@-webkit-keyframes shaking {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }
    2% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
    4% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }
    6% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }
    8% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg);
    }
    10% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg);
    }
    12% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }
    14% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }
    16% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }
    18% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg);
    }
    20% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}

@keyframes shaking {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }
    2% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
    4% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }
    6% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }
    8% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg);
    }
    10% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg);
    }
    12% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }
    14% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }
    16% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }
    18% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg);
    }
    20% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}


/* Custom Scrollbar */
::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #e73667;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #003049;
}

/* toTop Button */
#scrollUp {
    background: rgba(231, 54, 103, 0.3);
    color: #e73667;
    right: 30px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
}

#scrollUp:hover {
    background: #e73667;
    color: #ffffff;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: initial;
    }
}

.centered {
    text-align: center;
}

.coin-left {
    position: relative;
}

.coin-left::before {
    height: 225px;
    width: 200px;
    position: absolute;
    content: "";
    left: 100px;
    top: 100px;
    /* background: url("../images/coin.png") repeat; */
    z-index: -1;
    opacity: 0.3;
}

@media (max-width: 991px) {
    .coin-left::before {
        display: none;
    }
}

.coin-right {
    position: relative;
}

.coin-right::after {
    height: 225px;
    width: 200px;
    position: absolute;
    content: "";
    right: 100px;
    top: 100px;
    /* background: url("../images/coin.png") repeat; */
    z-index: -1;
    opacity: 0.3;
}

@media (max-width: 991px) {
    .coin-right::after {
        display: none;
    }
}

/* Custom css for Dark dropdown */
.dropdown-menu-dark {
    background: #001219;
    padding-top: 5px;
    padding-bottom: 0;
    border-radius: 4px;
    border: 0;
    min-width: 200px;
}

.dropdown-menu-dark li {
    margin-top: 10px;
}

.dropdown-menu-dark li .dropdown-item {
    padding: 8px 15px;
    border-radius: 4px;
}

.dropdown-menu-dark li .dropdown-item i {
    margin-right: 5px;
}

.dropdown-menu-dark li .dropdown-item:hover {
    background: #e73667;
    color: #ffffff;
}

/* Site Card */
.site-card {
    box-shadow: 0px 0px 2px rgba(0, 48, 73, 0.4);
    margin-bottom: 30px;
    background: #ffffff;
    border-radius: 4px;
}

.site-card .site-card-header {
    padding: 15px 20px;
    border-bottom: 1px solid #eeeeee;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.site-card .site-card-header .title {
    color: #003049;
    margin-bottom: 0;
    font-size: 18px;
    display: inline-block;
}

@media (max-width: 500px) {
    .site-card .site-card-header .title {
        font-size: 16px;
    }
}

.site-card .site-card-header .title-small {
    color: #003049;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.site-card .site-card-header .card-header-links .card-header-link {
    display: inline-block;
    background: #e73667;
    border-radius: 3px;
    padding: 8px 18px;
    border: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 10px;
}

.site-card .site-card-header .card-header-links .card-header-link:hover {
    background: #003049;
}

.site-card .site-card-header .search {
    font-size: 14px;
}

.site-card .site-card-header .search span {
    margin-right: 5px;
}

.site-card .site-card-header .search input {
    width: 200px;
    height: 27px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 0 5px;
    background: transparent;
}

.site-card .site-card-body {
    padding: 20px;
}

.site-card .site-card-body .body-title {
    font-size: 16px;
    margin-bottom: 10px;
}

.site-card .site-card-body .paragraph {
    font-size: 14px;
}

@media (max-width: 400px) {
    .site-card .site-card-body {
        padding: 12px;
    }
}

/* site-alert */
.site-alert {
    color: rgba(0, 18, 25, 0.6);
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 2px #5468ff66;
    margin-bottom: 30px;
    background: rgba(255, 195, 0, 0.1);
    border-radius: 4px;
    padding: 10px 15px;
}

.site-alert .content {
    font-size: 18px;
}

.site-alert .content .icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    background: rgba(255, 195, 0, 0.5);
    color: #001219;
    margin-right: 8px;
    display: inline-block;
}

@media (max-width: 991px) {
    .site-alert {
        display: block;
    }

    .site-alert .content {
        margin-bottom: 15px;
    }

    .site-alert .action a {
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 0px !important;
    }
}

/* Site Badge */
.site-badge {
    padding: 2px 10px;
    border-radius: 50px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}

.site-badge.badge-success {
    background: #2a9d8f;
}

.site-badge.badge-failed {
    background: #ef476f;
}

.site-badge.badge-pending {
    background: #ffc300;
    color: rgba(0, 18, 25, 0.7);
}

.input-info-text {
    font-size: 12px;
    color: rgba(239, 71, 111, 0.7);
    margin-top: -18px;
}

/* Image Preview */
.wrap-custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 150px;
    text-align: center;
    border: 1px dashed #d2d3d8;
    border-radius: 8px;
}

.wrap-custom-file input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
    overflow: hidden;
    opacity: 0;
}

.wrap-custom-file label {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    border-radius: 8px;
    transition: transform 0.4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    transition: transform 0.4s;
}

.wrap-custom-file label span {
    display: block;
    color: #003049;
    font-size: 14px;
    transition: color 0.4s;
}

.wrap-custom-file label .upload-icon {
    width: 40px;
    margin: 0 auto;
    margin-bottom: 15px;
}

.wrap-custom-file label.file-ok {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.wrap-custom-file label.file-ok span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.3rem;
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    background-color: rgba(231, 54, 103, 0.5);
}

.wrap-custom-file label.file-ok .upload-icon {
    display: none;
}

.wrap-custom-file label .recomended {
    color: rgba(42, 157, 143, 0.6);
    font-size: 14px;
    position: absolute;
    transform: translate(-50%, -50%);
    bottom: 0;
    left: 50%;
}

/* bat-left */
.bat-left {
    height: 166px;
    width: 300px;
    position: absolute;
    left: 100px;
    top: 100px;
    z-index: -1;
    opacity: 0.5 !important;
}

@media (max-width: 767px) {
    .bat-left {
        right: 0;
    }
}

@media (max-width: 1199px) {
    .bat-left {
        display: none;
    }
}

/* bat-right */
.bat-right {
    height: 166px;
    width: 300px;
    position: absolute;
    right: 100px;
    top: 100px;
    z-index: -1;
    opacity: 0.5 !important;
}

@media (max-width: 767px) {
    .bat-right {
        right: 0;
    }
}

@media (max-width: 1333px) {
    .bat-right {
        display: none;
    }
}

/* Slider Thumb */
.slider-thumb {
    position: relative;
    z-index: 1;
}

.slider-thumb::before {
    position: absolute;
    content: "";
    left: 10%;
    top: -70px;
    width: 250px;
    height: 250px;
    background: linear-gradient(90deg, #e52e71, #ff8a00);
    border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
    will-change: border-radius, transform, opacity;
    animation: sliderShape 5s linear infinite;
    display: block;
    z-index: -1;
    -webkit-animation: sliderShape 5s linear infinite;
}

@media (max-width: 767px) {
    .slider-thumb::before {
        opacity: 0.3;
    }
}

@keyframes sliderShape {
    0%,
    100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    34% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }
    50% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}

/* Dark Theme */
.dark-theme {
    color: rgba(255, 255, 255, 0.9) !important;
    background: #032836 !important;
}

.dark-theme .panel-layout .panel-header .logo {
    background: #003049;
    border-right: 1px solid rgba(83, 90, 148, 0.5);
    border-bottom: 1px solid rgba(83, 90, 148, 0.5);
}

.dark-theme .panel-layout .panel-header .nav-wrap {
    background: #003049;
    border-bottom: 1px solid rgba(83, 90, 148, 0.5);
}

.dark-theme .panel-layout .panel-header .nav-wrap .nav-left .sidebar-toggle {
    background: #535a94;
    color: #ffffff;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-left
.sidebar-toggle:hover {
    background: #e73667;
    color: #ffffff;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.color-switcher {
    color: #ffffff;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.color-switcher
svg.light-icon {
    display: inline-block;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.color-switcher
svg.dark-icon {
    display: none;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.site-nice-select {
    color: rgba(255, 255, 255, 0.9);
    background: #535a94;
    border: 1px solid rgba(231, 54, 103, 0.1);
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.site-nice-select::after {
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    border-right: 2px solid rgba(255, 255, 255, 0.5);
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.site-nice-select
.list {
    box-shadow: 0px 0px 2px #aab0c6;
    background: rgba(0, 18, 25, 0.8);
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.site-nice-select
.list
li {
    background: rgba(0, 18, 25, 0.8);
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.site-nice-select
.list
li:hover {
    background: #e73667;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.item {
    background: #535a94;
    border: 1px solid rgba(231, 54, 103, 0.1);
    color: rgba(255, 255, 255, 0.9);
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.item:hover {
    background: rgba(231, 54, 103, 0.9);
    color: #ffffff;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu {
    box-shadow: 0px 0px 2px #aab0c6;
    background: rgba(0, 18, 25, 0.9);
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li
.dropdown-item {
    color: #ffffff;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li
.dropdown-item:hover {
    background: #e73667;
    color: #ffffff;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li.logout
.dropdown-item {
    background: rgba(239, 71, 111, 0.1);
    color: #ef476f;
    border-radius: 0 0 4px 4px;
}

.dark-theme
.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li.logout
.dropdown-item:hover {
    background: #ef476f;
    color: #ffffff;
}

.dark-theme .panel-layout .side-nav {
    background: #003049;
    border-right: 1px solid rgba(83, 90, 148, 0.5);
}

.dark-theme
.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item
a {
    color: rgba(255, 255, 255, 0.7);
}

.dark-theme
.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item
a:after {
    background: rgba(255, 255, 255, 0.2);
}

.dark-theme
.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item
a:hover {
    color: #ffffff;
}

.dark-theme
.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.active
a {
    color: #ffffff;
}

.dark-theme .panel-layout .site-card {
    background: #041f2c;
    border: 1px solid rgba(83, 90, 148, 0.3);
}

.dark-theme .panel-layout .site-card .site-card-header {
    border-bottom: 1px solid #003049;
}

.dark-theme .panel-layout .site-card .site-card-header .title {
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme .panel-layout .site-card .site-card-header .search input {
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .panel-layout .site-card .site-card-body {
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.referral-link
.referral-link-form
input {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.referral-link
.referral-joined {
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps
.single-step {
    background: rgba(42, 157, 143, 0.3);
    border-color: rgba(255, 195, 0, 0.5);
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps
.single-step
.content
h4 {
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
label {
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.input-group
.form-control {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.input-group
.input-group-text {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.input-group
.form-control-textarea {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.input-group
.form-select {
    border-color: rgba(255, 255, 255, 0.2);
    color: #003049;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.user-panel-title {
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.user-panel-title
h3 {
    color: #ffffff;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.table {
    color: #ffffff;
    border: 1px solid #003049;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.table
tbody
tr {
    border-bottom: 1px solid #003049;
}

.dark-theme
.panel-layout
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.table
tbody
tr
td
strong {
    color: rgba(255, 255, 255, 0.7);
}

.dark-theme .panel-layout .site-card .site-card-body .access-device .icon img {
    filter: invert(1);
}

.dark-theme .panel-layout .site-alert {
    background: rgba(0, 48, 73, 0.7);
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme .panel-layout .side-wallet-box {
    background: #003049;
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme .panel-layout .side-wallet-box .user-balance-card::before {
    background: rgba(255, 195, 0, 0.4);
}

.dark-theme .panel-layout .btn-link {
    color: #e73667;
}

.dark-theme .panel-layout .site-transactions .single {
    background: #041f2c;
}

.dark-theme .panel-layout .site-transactions .single .left .icon {
    background: #003049;
    color: #ffffff;
}

.dark-theme .panel-layout .site-transactions .single .left .content .date {
    color: rgba(255, 255, 255, 0.4);
}

.dark-theme .panel-layout .site-transactions .single.head {
    background: transparent;
}

.dark-theme .panel-layout .site-pagination .page-item .page-link {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 700;
    background: #003049;
    border-color: #003049;
}

.dark-theme .panel-layout .site-pagination .page-item.active .page-link {
    background: #e73667;
    color: #ffffff;
}

.dark-theme .header {
    background: #032836;
}

.dark-theme .header .navbar .main-nav .nav-item a.nav-link {
    color: #ffffff;
}

.dark-theme .header .navbar .main-nav .nav-item.dropdown .dropdown-menu {
    background: #032836;
}

.dark-theme
.header
.navbar
.main-nav
.nav-item.dropdown
.dropdown-menu
li
.dropdown-item {
    color: #ffffff;
}

.dark-theme .header .navbar .header-right-btn .color-switcher {
    color: #ffffff;
}

.dark-theme .header .navbar .header-right-btn .color-switcher svg.light-icon {
    display: inline-block;
}

.dark-theme .header .navbar .header-right-btn .color-switcher svg.dark-icon {
    display: none;
}

.dark-theme .banner .banner-content::before {
    background: rgba(255, 255, 255, 0.08);
}

.dark-theme .banner .banner-content h2 {
    color: rgba(255, 255, 255, 0.9);
}

.dark-theme .how-it-works .how-it-works-single h4 {
    color: #ffffff;
}

.dark-theme .how-it-works .how-it-works-single p {
    color: rgba(255, 255, 255, 0.5);
}

.dark-theme .how-it-works .how-it-works-single::before {
    filter: brightness(0) invert(1);
}

@media (max-width: 575px) {
    .dark-theme .how-it-works .how-it-works-single::before {
        display: none;
    }
}

.dark-theme .why-choose-us .why-choose-us-content .single .content h4 {
    color: #ffffff;
}

.dark-theme .why-choose-us .why-choose-us-content .single .content p {
    color: rgba(255, 255, 255, 0.6);
}

.dark-theme .faq-contents .accordion-item {
    border-bottom: 2px solid rgba(231, 54, 103, 0.2);
}

.dark-theme .faq-contents .accordion-item .accordion-header .accordion-button {
    background: rgba(231, 54, 103, 0.1);
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme
.faq-contents
.accordion-item
.accordion-header
.accordion-button:not(.collapsed) {
    background: rgba(231, 54, 103, 0.7);
    color: #ffffff;
}

.dark-theme
.faq-contents
.accordion-item
.accordion-header
.accordion-button:not(.collapsed)
.icon {
    background: #ffc754e6;
    color: #001219;
}

.dark-theme .faq-contents .accordion-item .accordion-body .para {
    color: rgba(255, 255, 255, 0.6);
}

.dark-theme .faq-contents .accordion-item .accordion-body .list ul li {
    color: rgba(255, 255, 255, 0.6);
}

.dark-theme .single-blog {
    background: #032836;
}

.dark-theme .single-blog .content {
    color: rgba(255, 255, 255, 0.6);
}

.dark-theme .single-blog .content .title h3 a {
    color: #ffffff;
}

.dark-theme .single-blog .content .link a {
    color: #ffffff;
}

.dark-theme .brands-logo .single-brands-logo img {
    filter: brightness(0) invert(1);
}

.dark-theme .light-blue-bg {
    background: #003049;
}

.dark-theme .dark-blue-bg {
    background: #003049;
}

.dark-theme .section-title h4 {
    color: #ffc300;
}

.dark-theme .site-card {
    background: #041f2c;
}

.dark-theme .site-card .site-card-header {
    border-bottom: 1px solid #003049;
}

.dark-theme .site-card .site-card-header .title {
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme .site-card .site-card-header .search input {
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .site-card .site-card-body {
    color: #ffffff;
}

.dark-theme
.site-card
.site-card-body
.referral-link
.referral-link-form
input {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme .site-card .site-card-body .referral-link .referral-joined {
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme .site-card .site-card-body .progress-steps .single-step {
    background: rgba(42, 157, 143, 0.3);
    border-color: rgba(255, 195, 0, 0.5);
}

.dark-theme
.site-card
.site-card-body
.progress-steps
.single-step
.content
h4 {
    color: #ffffff;
}

.dark-theme .site-card .site-card-body .progress-steps-form label {
    color: #ffffff;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.input-group
.form-control {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.input-group
.input-group-text {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.input-group
.form-control-textarea {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.input-group
.form-select {
    border-color: rgba(255, 255, 255, 0.2);
    color: #003049;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.user-panel-title {
    color: #ffffff;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.user-panel-title
h3 {
    color: #ffffff;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.table {
    color: #ffffff;
    border: 1px solid #003049;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.table
tbody
tr {
    border-bottom: 1px solid #003049;
}

.dark-theme
.site-card
.site-card-body
.progress-steps-form
.transaction-list
.table
tbody
tr
td
strong {
    color: rgba(255, 255, 255, 0.7);
}

.dark-theme .site-card .site-card-body .access-device .icon img {
    filter: invert(1);
}

.dark-theme .site-alert {
    background: rgba(0, 48, 73, 0.7);
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme .side-wallet-box {
    background: #041f2c;
    color: rgba(255, 255, 255, 0.8);
}

.dark-theme .side-wallet-box .user-balance-card::before {
    background: rgba(255, 195, 0, 0.4);
}

.dark-theme .btn-link {
    color: #e73667;
}

.dark-theme .site-transactions .single {
    background: #041f2c;
}

.dark-theme .site-transactions .single .left .icon {
    background: #003049;
    color: #ffffff;
}

.dark-theme .site-transactions .single .left .content .date {
    color: rgba(255, 255, 255, 0.4);
}

.dark-theme .site-transactions .single.head {
    background: transparent;
}

.dark-theme .site-pagination .page-item .page-link {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 700;
    background: #003049;
    border-color: #003049;
}

.dark-theme .site-pagination .page-item.active .page-link {
    background: #e73667;
    color: #ffffff;
}

.dark-theme .single-investment-plan p {
    color: rgba(255, 195, 0, 0.8);
}

.dark-theme .site-datatable .dataTables_length label {
    font-weight: 700 !important;
}

.dark-theme .site-datatable .dataTables_length label select:focus {
    box-shadow: none;
    border: 1px solid rgba(231, 54, 103, 0.5);
}

.dark-theme .site-datatable .dataTables_filter label input {
    background: transparent;
    color: #ffffff;
}

.dark-theme .site-datatable .dataTables_filter label input:focus {
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.dark-theme .site-datatable .dataTables_info {
    color: rgba(255, 255, 255, 0.7);
}

.dark-theme
.site-datatable
.dataTables_paginate
.pagination
.paginate_button
.page-link {
    background: #003049;
    color: rgba(255, 255, 255, 0.7);
    border-color: #003049;
}

.dark-theme
.site-datatable
.dataTables_paginate
.pagination
.paginate_button
.page-link:hover {
    background: #e73667;
    color: #ffffff;
}

.dark-theme
.site-datatable
.dataTables_paginate
.pagination
.paginate_button
.page-link:focus {
    box-shadow: none;
}

.dark-theme
.site-datatable
.dataTables_paginate
.pagination
.paginate_button.active
.page-link {
    background: #e73667;
    color: #ffffff;
    border-color: #e73667;
}

.dark-theme .site-datatable .data-table {
    background: #003049;
    box-shadow: 0px 0px 2px #aab0c6;
}

.dark-theme .site-datatable .data-table thead tr th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.1);
}

.dark-theme .site-datatable .data-table tbody tr td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.dark-theme .site-datatable .data-table tbody tr td .avatar-text {
    color: #ffffff;
}

.dark-theme
.site-datatable
.data-table
tbody
tr
td
.form-switch
input.form-check-input:checked {
    background-color: #e73667;
    border-color: #e73667;
}

.dark-theme .site-datatable .data-table tbody tr td .site-badge {
    color: #ffffff;
}

.dark-theme .site-datatable .data-table tbody tr td .site-badge.success {
    background: #2a9d8f;
}

.dark-theme .site-datatable .data-table tbody tr td .site-badge.failed {
    background: #ef476f;
}

.dark-theme .site-datatable .data-table tbody tr td .site-badge.warnning {
    background: #ffc300;
    color: #001219;
}

.dark-theme .site-datatable .data-table tbody tr td .link {
    color: #e73667;
}

.dark-theme .site-datatable .data-table tbody tr td .link:hover {
    color: #2a9d8f;
}

.dark-theme .site-datatable .data-table tbody tr td .investment-timeline {
    background: #d2d3d8;
}

.dark-theme
.site-datatable
.data-table
tbody
tr
td
.investment-timeline
.progress-bar {
    background: #ef476f;
}

.dark-theme .site-datatable .data-table tbody tr td .table-description .icon {
    background: rgba(83, 90, 148, 0.7);
    color: #ffffff;
}

.dark-theme .site-datatable .data-table tbody tr td .date {
    color: rgba(255, 255, 255, 0.75);
}

.dark-theme .site-datatable .data-table tbody tr:hover {
    background: rgba(255, 255, 255, 0.02);
}

.dark-theme .progress-steps-form .transaction-status {
    background: #003049;
    color: #ffffff;
}

.dark-theme .progress-steps-form .input-group .site-nice-select {
    background: #041f2c;
    border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .progress-steps-form .input-group .site-nice-select .list {
    background: #003049;
    color: #ffffff;
}

.dark-theme
.progress-steps-form
.input-group
.site-nice-select
.list
li.selected {
    background: #020d24;
    color: #ffffff;
}

.dark-theme .progress-steps-form .input-group .site-nice-select .list li:hover {
    background: #020d24;
    color: #ffffff;
}

.dark-theme .progress-steps-form .input-group .site-nice-select::after {
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    border-right: 2px solid rgba(255, 255, 255, 0.5);
    height: 8px;
    width: 8px;
}

.dark-theme .wrap-custom-file label span {
    color: #ffffff;
}

.dark-theme .single-badge {
    border: 2px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .single-badge .content .description {
    color: rgba(255, 255, 255, 0.4);
}

.dark-theme .earnings-calculator {
    background: #032836;
}

.dark-theme .earnings-calculator .single-box label {
    color: rgba(255, 255, 255, 0.5);
}

.dark-theme .earnings-calculator .single-box .input-group .form-control {
    border-color: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme .earnings-calculator .single-box .input-group .input-group-text {
    border-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
}

.dark-theme .earnings-calculator .single-box .site-nice-select {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .earnings-calculator .single-box .site-nice-select .current {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
}

.dark-theme .earnings-calculator .single-box .site-nice-select .list {
    background: #003049;
    color: #ffffff;
    width: 100%;
}

.dark-theme
.earnings-calculator
.single-box
.site-nice-select
.list
li.selected {
    background: #020d24;
    color: #ffffff;
}

.dark-theme .earnings-calculator .single-box .site-nice-select .list li:hover {
    background: #020d24;
    color: #ffffff;
}

.dark-theme .earnings-calculator .single-box .site-nice-select::after {
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    border-right: 2px solid rgba(255, 255, 255, 0.5);
    height: 8px;
    width: 8px;
}

.dark-theme .support-ticket-single-message {
    background: #003049;
}

.dark-theme
.support-ticket-single-message
.message-attachments
.single-attachment
.attach
a {
    color: #2a9d8f;
}

.dark-theme .about-us .about-content .content {
    color: rgba(255, 255, 255, 0.9);
}

.dark-theme .site-form form input,
.dark-theme .site-form form textarea {
    border: 2px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.dark-theme .site-form form input:focus,
.dark-theme .site-form form textarea:focus {
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.dark-theme .site-form form input:focus-visible,
.dark-theme .site-form form textarea:focus-visible {
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.dark-theme .site-form form input::placeholder,
.dark-theme .site-form form textarea::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.dark-theme .site-form form select {
    background: rgba(255, 255, 255, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.4);
}

.dark-theme .site-form form select:focus {
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.dark-theme .site-form form .extra-links a {
    color: rgba(0, 18, 25, 0.5);
}

.dark-theme .site-tab-bars {
    background: #003049;
}

.dark-theme .site-tab-bars ul li {
    display: inline-block;
}

@media (max-width: 991px) {
    .dark-theme .site-tab-bars ul li {
        margin-bottom: 10px;
        margin-right: 10px;
    }
}

.dark-theme .site-tab-bars ul li a {
    color: #003049;
    font-weight: 700;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 4px;
}

.dark-theme .site-tab-bars ul li a svg {
    width: 16px;
    position: relative;
    margin-right: 5px;
    top: -1px;
}

.dark-theme .site-tab-bars ul li a:hover {
    color: #e73667;
}

@media (max-width: 575px) {
    .dark-theme .site-tab-bars ul li a {
        background: rgba(231, 54, 103, 0.2);
        display: block;
    }
}

.dark-theme .site-tab-bars ul li.active a {
    background: #e73667;
    color: #ffffff;
}

.dark-theme .site-tab-bars .nav.nav-pills .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.6);
}

.dark-theme .site-tab-bars .nav.nav-pills .nav-item .nav-link.active {
    background: #e73667;
    color: #ffffff;
}

.dark-theme .caches-privacy {
    background: #535a94;
}

/* Header Top */
.header-top {
    padding: 15px 0;
}

.header-top i {
    margin-right: 8px;
}

.header-top .header-top-address address {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .header-top .header-top-address address {
        margin-bottom: 15px;
    }
}

@media (max-width: 767px) {
    .header-top .header-top-address {
        text-align: center;
    }
}

.header-top .header-top-phone {
    float: right;
}

@media (max-width: 767px) {
    .header-top .header-top-phone {
        float: none;
        text-align: center;
    }
}

.header-top .header-top-phone p {
    margin-bottom: 0;
    display: inline-block;
    margin-left: 25px;
    transition: 0.3s;
}

.header-top .header-top-phone p:hover {
    color: #ffffff;
}

.header-top .header-top-phone p a {
    color: rgba(255, 255, 255, 0.5);
    position: relative;
}

.header-top .header-top-phone p a::after {
    position: absolute;
    content: "";
    height: 1px;
    background: #ffffff;
    width: 0px;
    transition: 0.3s;
    left: 0;
    bottom: 0px;
}

.header-top .header-top-phone p a:hover {
    color: #ffffff;
}

.header-top .header-top-phone p a:hover::after {
    width: 100%;
    color: #ffffff;
}

/* Navigation */
.header {
    border-bottom: 1px solid rgba(231, 54, 103, 0.1);
    background: #bee3db;
    position: fixed;
    width: 100%;
    z-index: 2;
}

@media (max-width: 991px) {
    .header {
        position: absolute;
        width: 100%;
        z-index: 2;
        max-height: 450px;
        overflow: auto;
        background: #ffffff;
    }
}

.header .navbar {
    padding: 20px 0;
}

.header .navbar .container-fluid {
    padding: 0 40px;
}

.header .navbar .navbar-brand img {
    height: 40px;
}

.header .navbar .navbar-toggler {
    border: none;
    background: #e73667;
    border-radius: 0;
}

.header .navbar .navbar-toggler:focus {
    box-shadow: none;
}

.header .navbar .main-nav .nav-item {
    margin: 0 12px;
}

@media (max-width: 991px) {
    .header .navbar .main-nav .nav-item {
        margin: 5px 0;
    }
}

.header .navbar .main-nav .nav-item a.nav-link {
    color: rgba(0, 18, 25, 0.8);
    position: relative;
    font-weight: 500;
    letter-spacing: -0.5px;
}

.header .navbar .main-nav .nav-item a.nav-link:hover {
    color: #e73667;
}

.header .navbar .main-nav .nav-item a.nav-link.active {
    color: #e73667;
}

.header .navbar .main-nav .nav-item a.nav-link.dropdown-toggle::after {
    position: absolute;
    border: 0px;
    content: "\e00b";
    font-family: "anticon" !important;
    font-weight: 700;
    top: 50%;
    font-size: 12px;
    transform: translateY(-50%);
    margin-left: 5px;
}

@media (max-width: 991px) {
    .header .navbar .main-nav .nav-item a.nav-link.dropdown-toggle::after {
        margin-left: 0px;
        right: 0;
    }
}

.header .navbar .main-nav .nav-item.dropdown .dropdown-menu {
    padding: 0;
    width: 240px;
    top: 66px;
    border-radius: 3px;
    background: #ffffff;
    margin: 0;
    border: 0;
}

@media (max-width: 991px) {
    .header .navbar .main-nav .nav-item.dropdown .dropdown-menu {
        width: 100%;
        box-shadow: none;
    }
}

.header .navbar .main-nav .nav-item.dropdown .dropdown-menu li {
    margin: 3px;
}

.header .navbar .main-nav .nav-item.dropdown .dropdown-menu li .dropdown-item {
    font-size: 14px;
    padding: 12px 10px 12px 20px;
    color: rgba(0, 18, 25, 0.8);
}

.header
.navbar
.main-nav
.nav-item.dropdown
.dropdown-menu
li
.dropdown-item:hover,
.header
.navbar
.main-nav
.nav-item.dropdown
.dropdown-menu
li
.dropdown-item:focus {
    border-radius: 3px;
    background: #e73667;
    color: #ffffff;
}

.header .navbar .main-nav .nav-item.dropdown .dropdown-menu li:hover {
    border-radius: 3px;
}

.header .navbar .header-right-btn {
    flex-direction: row;
    justify-content: end;
}

@media (max-width: 991px) {
    .header .navbar .header-right-btn {
        justify-content: start;
    }
}

.header .navbar .header-right-btn .color-switcher {
    color: #001219;
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
}

.header .navbar .header-right-btn .color-switcher svg {
    height: 18px;
}

.header .navbar .header-right-btn .color-switcher svg.dark-icon {
    display: inline-block;
}

.header .navbar .header-right-btn .color-switcher svg.light-icon {
    display: none;
}

.header .navbar .site-nice-select {
    color: #001219;
    border: 0;
    border-radius: 50px;
    display: inline-block;
    height: 40px;
}

@media (max-width: 991px) {
    .header .navbar .site-nice-select {
        background: rgba(210, 211, 216, 0.5);
    }
}

.header.sticky-header {
    padding: 0px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
}

.header .dropdown-menu-dark {
    padding-top: 15px;
}

/* Banner Section */
.banner {
    padding: 180px 0 90px 0;
}

@media (max-width: 991px) {
    .banner {
        padding-top: 150px;
    }
}

.banner .banner-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-right: 100px;
}

@media (max-width: 1199px) {
    .banner .banner-content {
        margin-right: 0px;
    }
}

@media (max-width: 991px) {
    .banner .banner-content {
        display: block;
        margin-bottom: 50px;
        margin-right: 0px;
    }
}

.banner .banner-content h2 {
    font-size: 72px;
    margin-bottom: 20px;
    color: rgba(0, 18, 25, 0.8);
    font-weight: 700;
}

@media (max-width: 991px) {
    .banner .banner-content h2 {
        font-size: 46px;
    }
}

.banner .banner-content p {
    font-size: 22px;
}

.banner .banner-content .banner-anchors {
    margin-top: 20px;
}

.banner .banner-content .banner-anchors a {
    margin-right: 15px;
}

.banner .banner-right {
    text-align: right;
    position: relative;
    z-index: 1;
}

.banner .banner-right .banner-img {
    border-radius: 8px;
}

@media (max-width: 757px) {
    .banner .banner-right .banner-img {
        width: 100%;
    }
}

.banner .banner-right .dots {
    height: 166px;
    width: 300px;
    position: absolute;
    top: -60px;
    right: -60px;
    z-index: -1;
    opacity: 0.5;
}

@media (max-width: 767px) {
    .banner .banner-right .dots {
        right: 0;
    }
}

@media (max-width: 1199px) {
    .banner .banner-right .dots {
        display: none;
    }
}

.banner .banner-content-2 img {
    width: 80px;
    margin-bottom: 20px;
    filter: brightness(0) invert(1);
}

.banner .banner-content-2 h4 {
    color: #ffc300;
    font-size: 18px;
    text-transform: uppercase;
}

.banner .banner-content-2 h2 {
    font-size: 60px;
    margin-bottom: 20px;
}

.banner .banner-content-2 p {
    font-size: 20px;
}

.banner .banner-content-2 .banner-anchors {
    margin-top: 40px;
}

.banner .banner-content-2 .banner-anchors a {
    margin: 0 8px;
}

/* Hero Area Slider */
.hero-area .single-hero {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.hero-area .single-hero .hero-content {
    color: #ffffff;
}

.hero-area .single-hero .hero-content h4 {
    margin-bottom: 10px;
    font-size: 22px;
    color: #ffc300;
}

.hero-area .single-hero .hero-content h1 {
    font-size: 62px;
    margin-bottom: 30px;
}

.hero-area .single-hero .hero-content p {
    font-size: 20px;
    margin-bottom: 30px;
}

.hero-area .owl-dots {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.hero-area .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 50%;
    margin: 0 3px;
    display: inline-block;
}

.hero-area .owl-dots .owl-dot span {
    color: #003049;
}

.hero-area .owl-dots .owl-dot.active {
    border-color: #ffc300;
    background: #ffc300;
}

/* Investment Plans */
.single-investment-plan {
    margin-bottom: 30px;
    padding: 40px 25px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    transition: 0.3s;
    border: 2px solid rgba(231, 54, 103, 0.5);
    position: relative;
}

.single-investment-plan:hover {
    transform: translate3d(0, -4px, 0);
}

.single-investment-plan .investment-plan-icon {
    margin-bottom: 25px;
    width: 50px;
}

.single-investment-plan .feature-plan {
    position: absolute;
    padding: 3px 7px;
    border-radius: 6px;
    background: linear-gradient(
        95deg,
        #059dff 15%,
        #6549d5 45%,
        #e33fa1 75%,
        #fb5343
    ) 95%/200% 100%;
    color: #ffffff;
    right: 10px;
    top: 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
}

.single-investment-plan h3 {
    font-size: 26px;
    margin-bottom: 0px;
}

.single-investment-plan p {
    font-size: 14px;
    margin-bottom: 30px;
    color: rgba(231, 54, 103, 0.8);
}

.single-investment-plan ul li {
    position: relative;
    margin-bottom: 20px;
    font-size: 14px;
}

.single-investment-plan ul li span {
    float: right;
}

.single-investment-plan ul li span.special {
    padding: 2px 8px;
    background: rgba(42, 157, 143, 0.6);
    border-radius: 8px;
}

.single-investment-plan a {
    margin-top: 20px;
}

/* Earnings Calculator */
.earnings-calculator-img {
    position: relative;
}

@media (max-width: 1199px) {
    .earnings-calculator-img {
        margin-bottom: 30px;
    }
}

.earnings-calculator-img .video {
    position: absolute;
    bottom: 15px;
    left: 15px;
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50%;
    background: #e73667;
    color: #ffffff;
    font-size: 16px;
    transition: 0.3s;
}

.earnings-calculator-img .video:hover {
    transform: scale(1.1);
}

.earnings-calculator {
    padding: 37px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 48, 73, 0.4);
}

.earnings-calculator .single-box {
    margin-bottom: 20px;
}

.earnings-calculator .single-box label {
    display: block;
    margin-bottom: 8px;
    color: #001219;
}

.earnings-calculator .single-box .plan-selects {
    color: #003049;
    background: #ffffff;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    height: 50px;
    outline: none;
}

.earnings-calculator .single-box .plan-selects:focus {
    box-shadow: none;
}

.earnings-calculator .single-box input {
    height: 50px;
    border-radius: 4px;
    padding: 0 15px;
}

.earnings-calculator .single-box input:focus {
    box-shadow: none;
}

.earnings-calculator .single-box .input-group .form-control {
    height: 50px;
    background: transparent;
    border-width: 2px;
    border-color: rgba(0, 48, 73, 0.2);
    outline: none;
    box-shadow: none;
    color: #001219;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 15px;
}

.earnings-calculator .single-box .input-group .form-control:disabled {
    color: #2a9d8f;
    border-color: rgba(42, 157, 143, 0.5);
}

.earnings-calculator .single-box .input-group .input-group-text {
    background: transparent;
    color: #001219;
    border-width: 2px;
    border-color: rgba(0, 48, 73, 0.2);
    border-radius: 0 4px 4px 0;
    padding: 0.375rem 2.75rem;
    font-weight: 500;
}

.earnings-calculator .single-box button {
    border: 0;
}

.earnings-calculator .single-box .site-nice-select {
    border-color: rgba(0, 18, 25, 0.2);
    border-width: 2px;
}

.earnings-calculator .single-box .site-nice-select .current {
    color: #001219;
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
}

.earnings-calculator .single-box .site-nice-select .list {
    width: 100%;
}

.earnings-calculator .single-box .site-nice-select::after {
    border-bottom: 2px solid rgba(0, 18, 25, 0.5);
    border-right: 2px solid rgba(0, 18, 25, 0.5);
    height: 8px;
    width: 8px;
}

/* About */
.about-us .about-img {
    position: relative;
    margin-bottom: 30px;
}

.about-us .about-img::before {
    position: absolute;
    width: 200px;
    height: 200px;
    left: -60px;
    top: 0;
    border-radius: 50%;
    background: rgba(231, 54, 103, 0.3);
    content: "";
}

.about-us .about-img img {
    border-radius: 50%;
    width: 500px;
}

@media (max-width: 757px) {
    .about-us .about-img img {
        width: 100%;
    }
}

.about-us .about-img .content {
    position: absolute;
    left: 0;
    bottom: 40px;
    padding: 10px 20px;
    background: #ffc300;
    color: #001219;
    font-size: 22px;
}

.about-us .about-content .content {
    color: rgba(0, 18, 25, 0.5);
}

/* How it works */
.how-it-works .how-it-works-single {
    margin-bottom: 60px;
    text-align: center;
    position: relative;
}

.how-it-works .how-it-works-single::before {
    position: absolute;
    content: "";
    /* background: url("../materials/arrow.png") no-repeat; */
    height: 70px;
    width: 80px;
    top: 20px;
    left: 100%;
    transform: rotate(25deg) translateX(-50%);
    filter: brightness(0) invert(0);
}

@media (max-width: 575px) {
    .how-it-works .how-it-works-single::before {
        display: none;
    }
}

.how-it-works .how-it-works-single .icon-box {
    height: 90px;
    width: 90px;
    text-align: center;
    line-height: 90px;
    border-radius: 8px;
    font-size: 32px;
    margin: auto;
    margin-bottom: 20px;
    background: #e73667;
    color: #ffffff;
    position: relative;
}

.how-it-works .how-it-works-single .icon-box span {
    position: absolute;
    top: -10px;
    left: -10px;
    background: #ffc300;
    color: #003049;
    height: 30px;
    display: inline-block;
    width: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 16px;
}

.how-it-works .how-it-works-single .icon-box .icon-box-icon {
    width: 46px;
    margin-top: -12px;
    filter: brightness(0) invert(1);
}

.how-it-works .how-it-works-single h4 {
    color: #001219;
    font-size: 20px;
    margin-bottom: 15px;
}

.how-it-works .how-it-works-single p {
    margin-bottom: 0px;
    color: rgba(0, 18, 25, 0.5);
}

.how-it-works .row [class*="col"]:last-child .how-it-works-single::before {
    display: none;
}

.how-it-works
.row
[class*="col"]:nth-of-type(4n + 4)
.how-it-works-single::before {
    display: none;
}

/* brands logos */
.brands-logo .single-brands-logo {
    text-align: center;
    margin-bottom: 60px;
}

.brands-logo .single-brands-logo img {
    height: 30px;
    width: auto;
    opacity: 0.5;
    transition: 0.3s;
}

.brands-logo .single-brands-logo img:hover {
    opacity: 0.9;
}

/* CTA */
.cta {
    padding: 120px 0;
}

.cta .headding h2 {
    font-size: 42px;
    margin-bottom: 0;
}

@media (max-width: 1199px) {
    .cta .headding {
        text-align: center;
        margin-bottom: 10px;
    }
}

.cta .btns {
    float: right;
    margin-top: 25px;
}

@media (max-width: 1199px) {
    .cta .btns {
        float: none;
        text-align: center;
    }
}

.cta .btns a {
    margin-left: 10px;
}

@media (max-width: 991px) {
    .cta .btns a {
        margin: 0px 5px;
    }
}

/* content-feature */
.content-feature .content-tab .nav {
    width: 380px;
}

.content-feature .content-tab .nav .nav-link {
    height: 100px;
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 25px;
    width: 100%;
}

.content-feature .content-tab .nav .nav-link.active {
    /* background: url("../images/banner-bg.jpg") no-repeat center center; */
    background-size: cover;
    position: relative;
    z-index: 1;
    color: #ffffff;
}

.content-feature .content-tab .nav .nav-link.active::after {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    left: 0;
    top: 0;
    background: #e73667;
    opacity: 0.4;
    z-index: -1;
    border-radius: 6px;
}

.content-feature .content-tab .nav .nav-link h4 {
    font-size: 18px;
    margin-bottom: 5px;
    line-height: initial;
    color: #ffffff;
}

.content-feature .content-tab .nav .nav-link p {
    font-size: 14px;
    margin-bottom: 0;
}

.content-feature .content-tab .tab-content {
    width: 100%;
    height: 100%;
}

.content-feature .content-tab .tab-content img {
    border-radius: 6px;
}

/* What we do */
.what-we-do .single-what-we-do {
    color: rgba(255, 255, 255, 0.5);
    padding: 40px 30px;
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    margin-bottom: 30px;
    transition: 0.3s;
}

.what-we-do .single-what-we-do i {
    font-size: 25px;
    margin-bottom: 30px;
}

.what-we-do .single-what-we-do h4 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #ffffff;
}

.what-we-do .single-what-we-do p {
    margin-bottom: 10px;
}

.what-we-do .single-what-we-do a {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
}

.what-we-do .single-what-we-do a i {
    margin-bottom: 0;
    margin-left: 5px;
    position: relative;
    top: 1px;
    font-size: 14px;
}

.what-we-do .single-what-we-do:hover {
    background: rgba(231, 54, 103, 0.2);
    border-color: rgba(231, 54, 103, 0.1);
    color: #ffffff;
}

.what-we-do .single-what-we-do:hover a:hover {
    color: #ffc300;
}

/* About */
.why-choose-us .why-choose-us-img {
    position: relative;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.why-choose-us .why-choose-us-img::before {
    position: absolute;
    width: 200px;
    height: 200px;
    left: -60px;
    top: 50px;
    border-radius: 50%;
    background: linear-gradient(90deg, #e52e71, #ff8a00);
    content: "";
    opacity: 0.6;
}

.why-choose-us .why-choose-us-img img {
    border-radius: 50%;
    width: 500px;
}

@media (max-width: 757px) {
    .why-choose-us .why-choose-us-img img {
        width: 100%;
    }
}

.why-choose-us .why-choose-us-content {
    margin-bottom: 30px;
}

.why-choose-us .why-choose-us-content .single {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
}

.why-choose-us .why-choose-us-content .single .icons .icon {
    height: 70px;
    width: 70px;
    line-height: 70px;
    border-radius: 8px;
    text-align: center;
    font-size: 24px;
    background: #e73667;
    color: #ffffff;
}

.why-choose-us .why-choose-us-content .single .content {
    padding-left: 20px;
}

.why-choose-us .why-choose-us-content .single .content h4 {
    color: rgba(0, 18, 25, 0.8);
    font-size: 20px;
    margin-bottom: 5px;
}

.why-choose-us .why-choose-us-content .single .content p {
    margin-bottom: 0;
}

/* Team Section */
.single-team {
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 6px;
    border: 2px solid;
    border-color: rgba(255, 255, 255, 0.1);
    text-align: center;
}

.single-team .thumb {
    text-align: center;
    margin-bottom: 30px;
}

.single-team .thumb img {
    border-radius: 50%;
    width: 150px;
    filter: grayscale(1);
}

.single-team .designation h4 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #ffffff;
}

.single-team .designation p {
    font-style: italic;
}

.single-team .designation .socials {
    margin-top: 20px;
}

.single-team .designation .socials a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    margin: 0 3px;
    border-radius: 6px;
    text-align: center;
    display: inline-block;
    background: rgba(231, 54, 103, 0.1);
    color: #ffffff;
}

.single-team .designation .socials a:hover {
    background: #e73667;
}

/* Counter Area */
.single-stat {
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
}

.single-stat img {
    width: 50px;
    margin-bottom: 25px;
}

.single-stat h3 {
    margin-bottom: 10px;
    font-size: 52px;
}

@media (max-width: 575px) {
    .single-stat h3 {
        font-size: 36px;
    }
}

.single-stat h4 {
    font-size: 22px;
}

@media (max-width: 575px) {
    .single-stat h4 {
        font-size: 18px;
    }
}

/* Blog Post */
.single-blog {
    margin-bottom: 30px;
    background: #ffffff;
    border-radius: 6px;
    transition: 0.3s;
}

.single-blog .thumb img {
    width: 100%;
    border-radius: 6px 6px 0 0;
}

.single-blog .content {
    padding: 25px 30px;
    color: rgba(0, 18, 25, 0.5);
}

.single-blog .content .meta {
    margin-bottom: 5px;
}

.single-blog .content .meta .date {
    font-size: 14px;
    font-style: italic;
}

.single-blog .content .title h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.single-blog .content .title h3 a {
    color: #001219;
}

.single-blog .content .title h3 a:hover {
    color: #e73667;
}

.single-blog .content .des p {
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.single-blog .content .link a {
    color: #001219;
    font-style: 15px;
    position: relative;
    font-weight: 500;
    font-style: 18px;
}

.single-blog .content .link a::after {
    position: absolute;
    content: "";
    height: 2px;
    background: #e73667;
    width: 0px;
    transition: 0.3s;
    left: 0;
    bottom: 0px;
}

.single-blog .content .link a:hover {
    color: #e73667;
}

.single-blog .content .link a:hover::after {
    width: 100%;
}

.single-blog:hover {
    transform: translate3d(0, -4px, 0);
}

/* Blog Details */
.blog-details {
    color: rgba(0, 18, 25, 0.5);
}

.blog-details img {
    width: 100%;
    margin-bottom: 30px;
}

.blog-details .blog-meta {
    margin-bottom: 10px;
}

.blog-details .blog-meta span a {
    margin-right: 10px;
    font-size: 14px;
    display: inline-block;
    color: #ffffff;
}

.blog-details .blog-meta span a:hover {
    color: #e73667;
}

.blog-details .blog-meta span a i {
    color: #e73667;
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
}

.blog-details h2 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 30px;
}

.blog-details .blockquote {
    font-size: 18px;
    font-style: italic;
    padding-left: 15px;
    margin: 20px 0;
    padding: 20px;
    background: #001219;
}

.blog-all-comments {
    color: rgba(230, 57, 70, 0.5);
}

.blog-all-comments h3 {
    margin-bottom: 20px;
    font-weight: 600;
    color: #ffffff;
}

.blog-all-comments .blog-comments {
    margin-bottom: 40px;
    padding: 30px 40px;
    overflow: hidden;
    background: rgba(230, 57, 70, 0.05);
    border-radius: 6px;
}

.blog-all-comments .blog-comments div {
    overflow: hidden;
}

.blog-all-comments .blog-comments .author-thumb {
    width: 20%;
    float: left;
}

.blog-all-comments .blog-comments .author-thumb img {
    border-radius: 6px;
    width: 70px;
}

.blog-all-comments .blog-comments .author-comments .author-details h4 {
    float: left;
    font-size: 18px;
}

.blog-all-comments
.blog-comments
.author-comments
.author-details
.comment-reply-btn {
    float: right;
}

.blog-all-comments .blog-comments .author-comments .author-designation {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 5px;
    font-style: italic;
}

.blog-comment-form {
    margin-bottom: 30px;
    color: rgba(230, 57, 70, 0.5);
}

.blog-comment-form h3 {
    margin-bottom: 15px;
    text-transform: uppercase;
}

.blog-comment-form input,
.blog-comment-form textarea {
    width: 100%;
    border: 2px solid rgba(230, 57, 70, 0.08);
    text-indent: 20px;
    margin-bottom: 30px;
    transition: 0.3s;
    background: transparent;
    color: #e63946;
    border-radius: 4px;
}

.blog-comment-form input::placeholder,
.blog-comment-form textarea::placeholder {
    color: rgba(230, 57, 70, 0.5);
}

.blog-comment-form input:focus,
.blog-comment-form textarea:focus {
    border-color: rgba(230, 57, 70, 0.5);
}

.blog-comment-form input {
    height: 50px;
}

.blog-comment-form textarea {
    padding-top: 15px;
}

.blog-comments + .blog-comments {
    margin-left: 15%;
}

@media (max-width: 767px) {
    .blog-comment-form {
        margin-bottom: 40px;
    }
}

.post-share-and-tag {
    margin-top: 40px;
}

.post-share-and-tag .social {
    color: #001219;
}

@media (max-width: 767px) {
    .post-share-and-tag .social {
        float: none;
        text-align: left;
        margin-top: 20px;
    }
}

.post-share-and-tag .social span {
    font-size: 20px;
}

.post-share-and-tag .social a {
    font-size: 18px;
    text-align: center;
    display: inline-block;
    margin: 0 5px;
    color: #001219;
}

.post-share-and-tag .social a:hover {
    color: #ffc300;
}

.post-share-and-tag .tags {
    color: #e63946;
}

.post-share-and-tag .tags a {
    color: #292929;
    text-transform: capitalize;
}

.post-share-and-tag .tags a:hover {
    color: #e63946;
}

.blog-details .big-thumb {
    width: 100%;
    margin-bottom: 30px;
}

.blog-details .blockquote {
    border-radius: 8px;
    background: #001219;
    padding: 30px;
    border-bottom: 2px solid rgba(231, 54, 103, 0.8);
}

/* Sidebar */
.site-sidebar .single-sidebar {
    margin-bottom: 40px;
    padding: 30px;
    color: #001219;
    border: 2px solid rgba(0, 18, 25, 0.2);
    border-radius: 8px;
}

.site-sidebar .single-sidebar div {
    overflow: hidden;
}

.site-sidebar .single-sidebar:last-child {
    margin-bottom: 0;
}

.site-sidebar .single-sidebar h3 {
    font-size: 24px;
    position: relative;
    margin-bottom: 30px;
    text-transform: capitalize;
}

.site-sidebar .single-sidebar form {
    width: 100%;
    position: relative;
    height: 50px;
    border-radius: 4px;
    border-radius: 50px;
}

.site-sidebar .single-sidebar form input {
    width: 100%;
    height: 100%;
    transition: 0.4s;
    padding: 0 15px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    color: #ffffff;
}

.site-sidebar .single-sidebar form input:focus {
    border-color: rgba(231, 54, 103, 0.2);
}

.site-sidebar .single-sidebar form input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.site-sidebar .single-sidebar form button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 50px;
    border-radius: 0 50px 50px 0;
}

.site-sidebar .single-sidebar form button:hover {
    color: #e73667;
}

.site-sidebar .single-sidebar ul li {
    margin-bottom: 20px;
    position: relative;
    transition: 0.4s;
}

.site-sidebar .single-sidebar ul li:last-child {
    margin-bottom: 0;
}

.site-sidebar .single-sidebar ul li a {
    color: #ffffff;
    display: inline-block;
    text-transform: capitalize;
    background: rgba(231, 54, 103, 0.3);
    padding: 7px 20px;
    border-radius: 50px;
}

.site-sidebar .single-sidebar ul li a:hover {
    color: #003049;
    background: #ffc300;
}

.site-sidebar .single-sidebar .social-follow a {
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    margin: 5px;
    height: 50px;
    width: 50px;
    line-height: 50px;
    background: rgba(231, 54, 103, 0.3);
}

.site-sidebar .single-sidebar .social-follow a:hover {
    background: #ffc300;
    color: #001219;
}

.site-sidebar .single-sidebar .social-follow a i {
    font-size: 18px;
}

@media (max-width: 991px) {
    .site-sidebar .single-sidebar .social-follow a {
        margin-bottom: 5px;
    }
}

@media (max-width: 767px) {
    .site-sidebar .single-sidebar .social-follow a {
        width: 100%;
    }
}

.site-sidebar .single-sidebar a img {
    width: 100%;
}

/* Newslatter */
.newslatter-section {
    border-bottom: 1px solid rgba(84, 104, 255, 0.1);
}

.newslatter-section .newslatter form {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 30px;
}

@media (max-width: 575px) {
    .newslatter-section .newslatter form {
        display: block;
    }
}

.newslatter-section .newslatter form input {
    height: 50px;
    flex: 1 1 70%;
    border: 2px solid;
    border-color: rgba(255, 255, 255, 0.3);
    background: transparent;
    color: #ffffff;
    transition: 0.3s;
    padding: 0 15px;
    margin-right: 20px;
    border-radius: 8px;
}

.newslatter-section .newslatter form input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.newslatter-section .newslatter form input:focus {
    border-color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 575px) {
    .newslatter-section .newslatter form input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        text-align: center;
    }
}

.newslatter-section .newslatter form button {
    height: 50px;
    flex: 1 1 30%;
    background: #e73667;
    color: rgba(255, 255, 255, 0.8);
    border: 2px solid;
    border-color: #e73667;
    font-size: 14px;
    border-radius: 8px;
    text-transform: uppercase;
}

@media (max-width: 575px) {
    .newslatter-section .newslatter form button {
        width: 100%;
    }
}

.newslatter-section .newslatter form button:hover {
    color: #ffffff;
}

/* Auth Section */
.site-auth {
    width: 100%;
    min-height: 100vh;
    background: #003049;
    background-size: cover;
    position: relative;
}

.site-auth .auth-content {
    padding: 40px 60px;
    margin-bottom: 30px;
    background: #ffffff;
    border-radius: 15px;
    position: relative;
    z-index: 1;
}

@media (max-width: 767px) {
    .site-auth .auth-content {
        width: 100%;
    }
}

@media (max-width: 575px) {
    .site-auth .auth-content {
        padding: 40px 15px;
    }
}

.site-auth .auth-content .logo {
    margin-bottom: 60px;
}

.site-auth .auth-content .logo a img {
    height: 50px;
}

@media (max-width: 575px) {
    .site-auth .auth-content .logo {
        margin-bottom: 80px;
    }
}

.site-auth .auth-content .title {
    margin-bottom: 40px;
}

.site-auth .auth-content .title h2 {
    color: #001219;
}

.site-auth .auth-content .title p {
    color: rgba(0, 18, 25, 0.6);
}

.site-auth .auth-content .site-auth-form .single-field {
    margin-bottom: 20px;
}

.site-auth .auth-content .site-auth-form .single-field .box-label {
    font-weight: 500;
    color: rgba(0, 18, 25, 0.8);
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
}

.site-auth
.auth-content
.site-auth-form
.single-field
.box-label
.required-field {
    color: #ef476f;
}

.site-auth .auth-content .site-auth-form .single-field .box-input {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    text-indent: 15px;
    background: #ffffff;
    transition: 0.3s;
    border: 1px solid transparent;
    position: relative;
    border: 1px solid #cdd4e7;
}

.site-auth .auth-content .site-auth-form .single-field .box-input::placeholder {
    color: rgba(0, 18, 25, 0.5);
}

.site-auth .auth-content .site-auth-form .single-field .box-input:focus {
    border: 1px solid #e73667;
}

.site-auth .auth-content .site-auth-form .single-field .site-nice-select {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    padding: 0 15px;
    line-height: 3;
    background: #ffffff;
    transition: 0.3s;
    border: 1px solid transparent;
    position: relative;
    font-size: 16px;
    border: 1px solid #cdd4e7;
    margin-bottom: 20px;
}

.site-auth .auth-content .site-auth-form .single-field .site-nice-select .list {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
}

.site-auth
.auth-content
.site-auth-form
.single-field
.site-nice-select::after {
    border-bottom: 2px solid rgba(0, 18, 25, 0.5);
    border-right: 2px solid rgba(0, 18, 25, 0.5);
    height: 8px;
    width: 8px;
}

.site-auth .auth-content .site-auth-form .single-field .joint-input {
    height: 50px;
    border-radius: 4px;
    border: 1px solid #cdd4e7;
}

.site-auth
.auth-content
.site-auth-form
.single-field
.joint-input
.input-group-text {
    background: rgba(255, 255, 255, 0.9);
    border: none;
}

.site-auth
.auth-content
.site-auth-form
.single-field
.joint-input
.form-control {
    border: none;
    border-left: 1px solid #dddddd87;
}

.site-auth
.auth-content
.site-auth-form
.single-field
.joint-input
.form-control:focus {
    box-shadow: none;
}

.site-auth .auth-content .site-auth-form .single-field .form-select {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    padding: 0 15px;
    line-height: 3;
    transition: 0.3s;
    border: 1px solid transparent;
    position: relative;
    font-size: 16px;
    border: 1px solid #cdd4e7;
}

.site-auth .auth-content .site-auth-form .single-field .password {
    position: relative;
}

.site-auth .auth-content .site-auth-form .single-field .password a {
    position: absolute;
    display: inline-block;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.site-auth .auth-content .site-auth-form .single-field .password a img {
    width: 32px;
    height: 17px;
}

.site-auth .auth-content .site-auth-form .single-field .check-input {
    margin-right: 5px;
    border: 2px solid #ffffff;
    border: 1px solid #cdd4e7;
    height: 1.3em;
    width: 1.3em;
    margin-top: 2px;
}

.site-auth .auth-content .site-auth-form .single-field .check-input:focus {
    box-shadow: none;
}

.site-auth .auth-content .site-auth-form .single-field .check-input:checked {
    background-color: #e73667;
    border: 2px solid #e73667;
}

.site-auth .auth-content .site-auth-form .single-field .form-check-label a {
    color: #e73667;
}

.site-auth
.auth-content
.site-auth-form
.single-field
.form-check-label
a:hover {
    color: #003049;
}

.site-auth .auth-content .site-auth-form .single-field .forget-pass-text {
    float: right;
}

.site-auth .auth-content .site-auth-form .single-field .forget-pass-text a {
    color: #001219;
}

.site-auth
.auth-content
.site-auth-form
.single-field
.forget-pass-text
a:hover {
    color: #e73667;
}

.site-auth .auth-content .site-auth-form button {
    margin-bottom: 20px;
}

.site-auth .auth-content .site-auth-form .singnup-text {
    text-align: center;
}

.site-auth .auth-content .site-auth-form .singnup-text p {
    margin-bottom: 0;
    color: #001219;
}

.site-auth .auth-content .site-auth-form .singnup-text p a {
    color: #e73667;
    position: relative;
}

.site-auth .auth-content .site-auth-form .singnup-text p a::after {
    position: absolute;
    content: "";
    height: 2px;
    background: #003049;
    width: 0px;
    transition: 0.3s;
    left: 0;
    bottom: -2px;
}

.site-auth .auth-content .site-auth-form .singnup-text p a:hover {
    color: #003049;
}

.site-auth .auth-content .site-auth-form .singnup-text p a:hover::after {
    width: 100%;
}

@media (max-width: 767px) {
    .site-auth {
        height: auto;
    }
}

/* User Panel Section */
.user-dashboard-content .user-sidebar {
    padding: 40px 30px;
    border-radius: 8px;
    background: #001219;
    margin-bottom: 30px;
}

.user-dashboard-content .user-sidebar ul {
    padding: 0;
}

.user-dashboard-content .user-sidebar ul li {
    margin-bottom: 10px;
}

.user-dashboard-content .user-sidebar ul li:last-child {
    margin-bottom: 0;
}

.user-dashboard-content .user-sidebar ul li a {
    color: #ffffff;
    font-size: 18px;
    display: block;
    padding: 12px 20px;
    border-radius: 8px;
    position: relative;
    background: #003049;
}

.user-dashboard-content .user-sidebar ul li a i {
    margin-right: 10px;
}

.user-dashboard-content .user-sidebar ul li a:hover {
    background: #e73667;
}

.user-dashboard-content .user-sidebar ul li.active a {
    background: #e73667;
}

.user-dashboard-content .user-sidebar ul li.active a::after {
    position: absolute;
    height: 100%;
    width: 7px;
    content: "";
    top: 0;
    left: 0;
    background: #ffc300;
    border-radius: 10px 0 0 10px;
}

.user-dashboard-content .user-cards {
    margin-bottom: 20px;
}

.user-dashboard-content .user-cards .single {
    background: #e73667;
    padding: 20px;
    border-radius: 8px;
    border: 2px solid rgba(255, 195, 0, 0.5);
    margin-bottom: 30px;
    transition: 0.4s;
    cursor: default;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.user-dashboard-content .user-cards .single::before {
    position: absolute;
    height: 65px;
    width: 65px;
    content: "";
    border-radius: 50%;
    background: rgba(255, 195, 0, 0.5);
    right: -25px;
    top: -25px;
}

.user-dashboard-content .user-cards .single:hover {
    transform: translate3d(0, -4px, 0);
}

.user-dashboard-content .user-cards .single .icon {
    font-size: 24px;
    height: 45px;
    width: 45px;
    line-height: 45px;
    border-radius: 50%;
    text-align: center;
    background: #ffffff;
    color: #535a94;
    display: inline-block;
    margin-right: 15px;
}

.user-dashboard-content .user-cards .single .content h4 {
    margin-bottom: 5px;
    color: #ffffff;
}

.user-dashboard-content .user-cards .single .content h4 b {
    font-weight: 400;
    font-size: 16px;
    position: relative;
    top: -8px;
    margin-right: 2px;
}

.user-dashboard-content .user-cards .single .content p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.9);
}

/* user-panel-title */
.user-panel-title {
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.user-panel-title h3 {
    font-size: 18px;
    color: #001219;
    display: inline-block;
    margin-bottom: 0;
}

/* Transaction Table */
.transaction-list {
    margin-top: 20px;
    margin-bottom: 30px;
}

.transaction-list .table {
    margin-bottom: 0;
    border: 1px solid rgba(0, 18, 25, 0.1);
}

.transaction-list .table thead {
    background: rgba(231, 54, 103, 0.2);
    color: #001219;
}

.transaction-list .table thead tr th {
    padding: 20px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
}

.transaction-list .table tbody tr {
    cursor: default;
}

.transaction-list .table tbody tr th {
    padding: 20px;
    vertical-align: middle;
}

.transaction-list .table tbody tr td {
    font-size: 16px;
    padding: 20px;
    vertical-align: middle;

}

.transaction-list .table tbody tr td strong {
    font-weight: 700;
}

.transaction-list .table tbody tr td span {
    font-size: 14px;
    font-weight: 300;
}

.transaction-list .table tbody tr td .payment-method {
    height: 40px;
}

.transaction-list .table tbody tr td a.view {
    color: #ffc300;
    font-size: 22px;
}

.transaction-list .table tbody tr td .badge {
    color: #ffffff;
    font-weight: 400;
}

.transaction-list .table tbody tr td .pending {
    background: #ffc300;
    color: #003049;
}

.transaction-list .table tbody tr td .success {
    background: #2a9d8f;
}

.transaction-list .table tbody tr td .completed {
    background: #ef476f;
}

/* User Ranking */
.user-ranking {
    margin-bottom: 30px;
    height: 200px;
    width: 200px;
    background: #5364f1;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 5px solid #ffc300;
    position: relative;
    z-index: 1;
    background-size: cover !important;
}

@media (max-width: 500px) {
    .user-ranking {
        margin: auto;
        margin-bottom: 30px;
    }
}

.user-ranking::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    background: #003049;
    opacity: 0.92;
    z-index: -1;
}

.user-ranking h4 {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 5px;
}

.user-ranking p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.6);
}

.user-ranking .rank {
    position: absolute;
    height: 50px;
    width: 50px;
    content: "";
    border-radius: 50%;
    right: 0px;
    top: 0px;
    background: #5364f1;
    line-height: 34px;
    border: 5px solid #ffc300;
}

.user-ranking .rank img {
    height: 25px;
}

/* Referral Link */
.referral-link .referral-link-form {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 7px;
}

@media (max-width: 575px) {
    .referral-link .referral-link-form {
        display: block;
    }
}

.referral-link .referral-link-form input {
    height: 50px;
    flex: 1 1 75%;
    border: 2px solid;
    border-color: rgba(0, 18, 25, 0.1);
    background: transparent;
    color: #001219;
    transition: 0.3s;
    padding: 0 15px;
    margin-right: 0px;
    border-radius: 4px 0 0 4px;
    border-right: 0;
    font-size: 18px;
}

.referral-link .referral-link-form input::placeholder {
    color: rgba(0, 18, 25, 0.8);
}

@media (max-width: 575px) {
    .referral-link .referral-link-form input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        text-align: center;
        border-radius: 4px;
        border-right: 2px solid rgba(0, 18, 25, 0.1);
    }
}

.referral-link .referral-link-form button {
    height: 50px;
    flex: 1 1 25%;
    background: #e73667;
    color: #ffffff;
    border: 2px solid;
    border-color: #e73667;
    font-size: 16px;
    border-radius: 0 4px 4px 0;
}

.referral-link .referral-link-form button i {
    margin-right: 5px;
}

@media (max-width: 575px) {
    .referral-link .referral-link-form button {
        width: 100%;
        border-radius: 4px;
    }
}

.referral-link .referral-link-form button:hover {
    color: #ffffff;
    background: #e73667;
    border-color: #e73667;
}

.referral-link .referral-joined {
    font-size: 14px;
    margin-bottom: 0;
    color: rgba(0, 18, 25, 0.4);
}

/* investment-modal */
.investment-modal .modal-header {
    border-bottom: 0;
}

.investment-modal .modal-header h5 {
    font-size: 26px;
}

.investment-modal .modal-header .btn-close {
    transition: 0.3s;
}

.investment-modal .modal-header .btn-close:hover {
    transform: rotate(90deg);
}

.investment-modal .modal-footer {
    border-top: 0;
}

.investment-modal .modal-body .input-group .form-control {
    height: 50px;
    border-width: 2px;
    border-color: #d2d3d8;
}

.investment-modal .modal-body .input-group .form-control:focus {
    outline: none;
    box-shadow: none;
}

.investment-modal .modal-body p {
    margin-top: 10px;
    margin-bottom: 0;
}

.investment-modal .modal-body p span {
    padding: 2px 8px;
    background: rgba(255, 195, 0, 0.6);
    border-radius: 8px;
    color: #003049;
}

/* progress-steps */
.progress-steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .progress-steps {
        display: none;
    }
}

.progress-steps .single-step {
    background: rgba(0, 18, 25, 0.1);
    padding: 10px 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: default;
    opacity: 0.3;
    margin-right: 20px;
    border: 2px solid rgba(0, 48, 73, 0.2);
    position: relative;
}

.progress-steps .single-step:last-child {
    margin-right: 0;
}

.progress-steps .single-step .number {
    background: #e73667;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    margin-right: 15px;
    font-size: 16px;
}

.progress-steps .single-step .content h4 {
    font-size: 18px;
    margin-bottom: 3px;
    color: #001219;
}

.progress-steps .single-step .content p {
    margin-bottom: 0;
    font-size: 14px;
}

.progress-steps .single-step.current {
    opacity: 1;
    background: #2a9d8f;
}

.progress-steps .single-step.current::after {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #ffc300;
    top: -10px;
    right: -10px;
    content: "";
}

.progress-steps .single-step.current .number {
    background: #ffc300;
    color: #003049;
}

.progress-steps .single-step.current .content h4 {
    color: #ffffff;
}

.progress-steps .single-step.current .content p {
    color: #ffffff;
}

/* progress-steps-form */
.progress-steps-form .form-label {
    color: #001219;
}

.progress-steps-form .input-group {
    margin-bottom: 20px;
}

.progress-steps-form .input-group .form-control {
    height: 50px;
    background: transparent;
    border-width: 2px;
    border-color: rgba(0, 18, 25, 0.2);
    outline: none;
    box-shadow: none;
    color: #001219;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 15px;
}

.progress-steps-form .input-group .form-control:focus {
    border-color: rgba(231, 54, 103, 0.5);
}

.progress-steps-form .input-group .form-control.textarea {
    height: 120px;
    padding-top: 10px;
}

.progress-steps-form .input-group .form-control.disabled {
    background: rgba(0, 18, 25, 0.1);
    opacity: 0.7;
}

.progress-steps-form .input-group .form-select {
    height: 50px;
    border-width: 2px;
    border-color: rgba(0, 18, 25, 0.2);
    outline: none;
    box-shadow: none;
    color: #001219;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 15px;
}

.progress-steps-form .input-group .form-select:focus {
    border-color: rgba(231, 54, 103, 0.5);
}

.progress-steps-form .input-group .form-select.disabled {
    background: rgba(0, 18, 25, 0.1);
    opacity: 0.7;
}

.progress-steps-form .input-group .input-group-text {
    background: transparent;
    color: #001219;
    border-width: 2px;
    border-color: rgba(0, 18, 25, 0.2);
    border-radius: 0 4px 4px 0;
    padding: 0.375rem 1.75rem;
    font-weight: 500;
    text-transform: uppercase;
}

.progress-steps-form .input-group .input-group-text:focus {
    border-color: rgba(231, 54, 103, 0.5);
}

.progress-steps-form .input-group .form-control-textarea {
    width: 100%;
    height: 120px;
    background: transparent;
    border-width: 2px;
    border-color: rgba(0, 18, 25, 0.2);
    outline: none;
    box-shadow: none;
    color: #001219;
    border-radius: 4px;
    font-size: 16px;
    padding: 15px;
    padding-top: 10px;
}

.progress-steps-form .input-group .form-control-textarea:focus {
    border-color: rgba(231, 54, 103, 0.5);
}

.progress-steps-form .input-group .site-nice-select {
    width: 100%;
    height: 50px;
    border-radius: 4px !important;
    padding: 0 15px;
    background: #ffffff;
    transition: 0.3s;
    position: relative;
    border-width: 2px;
    border-color: rgba(0, 18, 25, 0.2);
}

.progress-steps-form .input-group .site-nice-select .current {
    font-weight: 500;
    font-size: 16px;
    line-height: 46px;
}

.progress-steps-form .input-group .site-nice-select .list {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    box-shadow: 0px 0px 2px rgba(0, 48, 73, 0.4);
    background: #d2d3d8;
}

.progress-steps-form .input-group .site-nice-select::after {
    border-bottom: 2px solid rgba(0, 18, 25, 0.5);
    border-right: 2px solid rgba(0, 18, 25, 0.5);
    height: 8px;
    width: 8px;
    right: 15px;
}

.progress-steps-form .buttons {
    display: flex;
    flex: row;
    justify-content: space-between;
}

.progress-steps-form .buttons button i {
    position: relative;
    top: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.progress-steps-form .buttons a i {
    position: relative;
    top: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.progress-steps-form .single-choice {
    margin-bottom: 30px;
    text-align: center;
}

.progress-steps-form .single-choice .gateway-btn {
    cursor: pointer;
    padding: 15px 30px;
    border-radius: 8px;
    border: 2px solid rgba(231, 54, 103, 0.4);
    width: 100%;
}

.progress-steps-form .single-choice .gateway-btn .payment-icon {
    height: 30px;
}

.progress-steps-form .single-choice .gateway-btn:hover {
    background: rgba(231, 54, 103, 0.2);
}

.progress-steps-form .single-choice .btn-check:active + .gateway-btn,
.progress-steps-form .single-choice .btn-check:checked + .gateway-btn,
.progress-steps-form .single-choice .gateway-btn.active,
.progress-steps-form .single-choice .gateway-btn:active,
.progress-steps-form .single-choice .show > .gateway-btn.dropdown-toggle {
    background: rgba(231, 54, 103, 0.4);
    border: 2px solid rgba(231, 54, 103, 0.4);
}

.progress-steps-form
.single-choice
.btn-check:active
+ .gateway-btn
.payment-icon,
.progress-steps-form
.single-choice
.btn-check:checked
+ .gateway-btn
.payment-icon,
.progress-steps-form .single-choice .gateway-btn.active .payment-icon,
.progress-steps-form .single-choice .gateway-btn:active .payment-icon,
.progress-steps-form
.single-choice
.show
> .gateway-btn.dropdown-toggle
.payment-icon {
    opacity: 1;
}

.progress-steps-form .transaction-status {
    background: rgba(190, 227, 219, 0.8);
    padding: 50px 30px;
    border-radius: 8px;
    text-align: center;
    color: #001219;
    margin-bottom: 30px;
}

.progress-steps-form .transaction-status .icon {
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 24px;
}

.progress-steps-form .transaction-status .icon.success {
    background: #2a9d8f;
}

.progress-steps-form .transaction-status .icon.cancelled {
    background: #ef476f;
}

.progress-steps-form .transaction-status h2 {
    margin-bottom: 10px;
}

.progress-steps-form .transaction-status p {
    margin-bottom: 30px;
}

.single-progress-steps-form .form-select {
    height: 60px;
    border: 0px;
    outline: none;
    box-shadow: none;
    color: #001219;
    border-radius: 8px;
    font-size: 18px;
    padding-left: 20px;
}

.single-progress-steps-form .transaction-description {
    background: transparent;
    padding: 15px;
    border-radius: 8px;
    color: #ffffff;
    margin-bottom: 30px;
    width: 100%;
    border-width: 2px;
    border-color: rgba(231, 54, 103, 0.4);
    outline: none;
}

.single-progress-steps-form .transaction-description::placeholder {
    color: rgba(255, 255, 255, 0.3);
}

.user-buttons {
    display: flex;
    flex: row;
    justify-content: space-between;
}

.user-buttons button i {
    position: relative;
    top: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.user-buttons a i {
    position: relative;
    top: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

/* Site pagination */
.site-pagination {
    padding-top: 15px;
}

.site-pagination .page-item.active .page-link {
    background: #e73667;
    color: #ffffff;
    border-color: #e73667;
}

.site-pagination .page-item .page-link {
    color: #001219;
    font-size: 14px;
}

/* site form area */
.site-form form h4 {
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 10px;
}

.site-form form p {
    font-size: 16px;
    margin-bottom: 15px;
}

.site-form form input,
.site-form form textarea {
    width: 100%;
    border: 2px solid rgba(0, 18, 25, 0.1);
    background: transparent;
    color: #001219;
    text-indent: 15px;
    margin-bottom: 15px;
    font-size: 16px;
    border-radius: 4px;
}

.site-form form input:focus,
.site-form form textarea:focus {
    border: 2px solid rgba(0, 18, 25, 0.2);
}

.site-form form input:focus-visible,
.site-form form textarea:focus-visible {
    border: 2px solid rgba(0, 18, 25, 0.2);
}

.site-form form input::placeholder,
.site-form form textarea::placeholder {
    color: rgba(0, 18, 25, 0.4);
}

.site-form form input {
    height: 50px;
}

.site-form form select {
    height: 50px;
    background: #1c4256;
    border: 2px solid rgba(0, 18, 25, 0.1);
    color: rgba(0, 18, 25, 0.4);
}

.site-form form select:focus {
    border: 2px solid rgba(0, 18, 25, 0.2);
    outline: none;
    box-shadow: none;
}

.site-form form textarea {
    text-indent: 0;
    padding: 10px 15px;
}

.site-form form .extra-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
}

.site-form form .extra-links a {
    color: rgba(0, 18, 25, 0.5);
}

.site-form form .extra-links a:hover {
    color: #e73667;
}

/* Settings Tab */
.settings-tab {
    margin-top: 30px;
}

.settings-tab .nav .nav-item {
    margin-right: 10px;
}

.settings-tab .nav .nav-item .nav-link {
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
    padding: 12px 30px;
    border-radius: 8px;
}

.settings-tab .nav .nav-item .nav-link.active {
    background: #e73667;
}

.settings-tab .single {
    margin-bottom: 20px;
}

.settings-tab .single label {
    display: block;
}

.settings-tab .single .input-group .form-control {
    height: 60px;
    background: transparent;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.4);
    outline: none;
    box-shadow: none;
    color: #ffffff;
    border-radius: 8px;
    font-size: 18px;
    padding-left: 20px;
}

/* Footer Style */
.footer {
    position: relative;
    z-index: 1;
}

.footer .footer-widget {
    margin-bottom: 30px;
}

.footer .footer-widget h4 {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
}

.footer .footer-widget p {
    margin-bottom: 20px;
    padding-right: 40px;
    color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 991px) {
    .footer .footer-widget p {
        padding-right: 0;
    }
}

.footer .footer-widget .socials a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    color: #e73667;
    background: rgba(231, 54, 103, 0.2);
    display: inline-block;
    text-align: center;
    margin-right: 6px;
    font-size: 16px;
}

.footer .footer-widget .socials a:hover {
    color: #001219;
    background: #ffc300;
}

.footer .footer-widget .footer-nav ul {
    margin: 0;
    padding: 0;
}

.footer .footer-widget .footer-nav ul li {
    margin-bottom: 12px;
}

.footer .footer-widget .footer-nav ul li a {
    color: rgba(255, 255, 255, 0.44);
    position: relative;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.footer .footer-widget .footer-nav ul li a::after {
    position: absolute;
    content: "";
    height: 2px;
    background: #ffc300;
    width: 0px;
    transition: 0.3s;
    left: 0;
    bottom: 0px;
}

.footer .footer-widget .footer-nav ul li a:hover {
    color: #ffc300;
}

.footer .footer-widget .footer-nav ul li a:hover::after {
    width: 100%;
}

.footer .copyright-text {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    padding: 20px;
    margin: 30px 0;
    text-align: center;
}

.footer .copyright-text p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.5);
}

.footer .copyright-text p a {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
}

.footer .copyright-text p a:hover {
    color: #e73667;
}

/* faq-contents */
.faq-contents {
    margin-bottom: 30px;
}

.faq-contents .accordion-item {
    background: transparent;
    border: 0;
    border-bottom: 2px solid rgba(0, 18, 25, 0.1);
    padding: 15px 0;
    border-radius: 0;
}

.faq-contents .accordion-item .accordion-header .accordion-button {
    font-size: 20px;
    font-weight: 500;
    color: #003049;
    background: transparent;
}

.faq-contents .accordion-item .accordion-header .accordion-button .icon {
    height: 25px;
    width: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50%;
    background: rgba(231, 54, 103, 0.3);
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    color: #e73667;
}

.faq-contents .accordion-item .accordion-header .accordion-button:focus {
    outline: none;
    box-shadow: none;
}

.faq-contents .accordion-item .accordion-header .accordion-button::after {
    height: 20px;
    width: 20px;
    background: #e73667;
    border-radius: 50%;
}

.faq-contents
.accordion-item
.accordion-header
.accordion-button:not(.collapsed) {
    box-shadow: none;
    background: rgba(231, 54, 103, 0.1);
}

.faq-contents
.accordion-item
.accordion-header
.accordion-button:not(.collapsed)
.icon {
    background: rgba(231, 54, 103, 0.9);
    color: #ffffff;
}

.faq-contents
.accordion-item
.accordion-header
.accordion-button.collapsed::after {
    background: rgba(231, 54, 103, 0.3);
}

.faq-contents .accordion-item .accordion-body .para {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 48, 73, 0.5);
}

.faq-contents .accordion-item .accordion-body .para a {
    color: #e73667;
    font-size: 16px;
    font-weight: 500;
}

.faq-contents .accordion-item .accordion-body .list ul li {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 7px;
    position: relative;
    padding-left: 18px;
    color: rgba(0, 48, 73, 0.6);
}

.faq-contents .accordion-item .accordion-body .list ul li::before {
    position: absolute;
    content: "";
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background: rgba(231, 54, 103, 0.8);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

/* User Panel Sidebar Css */
.panel-layout {
    background: #21273e08;
}

.panel-layout .panel-header {
    background: #ffffff;
    height: 70px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 100;
    display: flex;
}

.panel-layout .panel-header .logo {
    width: 400px;
    background-color: #bee3db;
    border-right: 1px solid #dddddd85;
    border-bottom: 1px solid #dddddd85;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
}

@media (max-width: 991px) {
    .panel-layout .panel-header .logo {
        width: 0;
    }
}

.panel-layout .panel-header .logo a {
    display: block;
    text-align: center;
}

.panel-layout .panel-header .logo a .logo-unfold {
    height: 40px;
    margin-top: 16px;
}

.panel-layout .panel-header .logo a .logo-fold {
    display: none;
    width: 30px;
    margin: auto;
    margin-top: 21px;
}

.panel-layout .panel-header .nav-wrap {
    flex: 1;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd85;
}

.panel-layout .panel-header .nav-wrap .nav-left,
.panel-layout .panel-header .nav-wrap .nav-right {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 0px;
    align-items: center;
    display: flex;
}

.panel-layout .panel-header .nav-wrap .nav-left li a,
.panel-layout .panel-header .nav-wrap .nav-right li a {
    border-radius: 2px;
    color: #001219;
    background: transparent;
    line-height: 1;
    padding: 8px 12px;
    color: #001219;
    display: block;
    transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
}

.panel-layout .panel-header .nav-wrap .nav-left li a:hover,
.panel-layout .panel-header .nav-wrap .nav-right li a:hover {
    background: rgba(231, 54, 103, 0.1);
    color: #e73667;
}

.panel-layout .panel-header .nav-wrap .nav-left li a i,
.panel-layout .panel-header .nav-wrap .nav-right li a i {
    margin-right: 10px;
}

.panel-layout .panel-header .nav-wrap .nav-left .sidebar-toggle,
.panel-layout .panel-header .nav-wrap .nav-right .sidebar-toggle {
    border-radius: 4px;
    color: #001219;
    background: rgba(231, 54, 103, 0.1);
    color: #e73667;
    line-height: 1;
    padding: 8px 12px;
    transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
}

.panel-layout .panel-header .nav-wrap .nav-left .sidebar-toggle:hover,
.panel-layout .panel-header .nav-wrap .nav-right .sidebar-toggle:hover {
    background: rgba(231, 54, 103, 0.8);
    color: #ffffff;
}

.panel-layout .panel-header .nav-wrap .nav-left .sidebar-toggle svg,
.panel-layout .panel-header .nav-wrap .nav-right .sidebar-toggle svg {
    height: 22px;
}

@media (max-width: 991px) {
    .panel-layout .panel-header .nav-wrap .nav-left .sidebar-toggle,
    .panel-layout .panel-header .nav-wrap .nav-right .sidebar-toggle {
        display: block;
        transform: scaleX(-1);
    }
}

.panel-layout .panel-header .nav-wrap .nav-left {
    margin-left: 20px;
}

.panel-layout .panel-header .nav-wrap .nav-right {
    margin-right: 20px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right {
    display: flex;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right {
    display: inline-block;
    margin-left: 10px;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.color-switcher {
    color: #001219;
    cursor: pointer;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.color-switcher
svg {
    height: 18px;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.color-switcher
svg.dark-icon {
    display: inline-block;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.color-switcher
svg.light-icon {
    display: none;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.site-nice-select {
    height: 32px;
    border-radius: 4px;
    color: #001219e6;
    line-height: 2.15;
    padding: 0px 30px 0 12px;
    background: #efefef;
    transition: 0.3s;
    border: 1px solid #f8f8f9;
    position: relative;
    font-size: 14px;
    font-weight: 500;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.site-nice-select
.list {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.site-nice-select::after {
    border-bottom: 2px solid rgba(0, 18, 25, 0.5);
    border-right: 2px solid rgba(0, 18, 25, 0.5);
    height: 7px;
    width: 7px;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.item {
    display: inline-block;
    margin-left: 5px;
    border-radius: 4px;
    background: #efefef;
    border: 1px solid #f8f8f9;
    line-height: 1;
    padding: 8px 12px;
    color: rgba(0, 18, 25, 0.9);
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -ms-transition: background-color 0.3s;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.item:hover {
    background: rgba(231, 54, 103, 0.9);
    color: #ffffff;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu {
    padding: 0;
    border: none;
    box-shadow: 0px 0px 2px #aab0c6;
    background: #ffffff;
    margin-top: 18px !important;
    width: 190px;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li
.dropdown-item {
    padding: 12px 15px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li
.dropdown-item
i {
    margin-right: 10px;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li
.dropdown-item:hover {
    background: rgba(231, 54, 103, 0.1);
    color: #e73667;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li.logout
.dropdown-item {
    background: rgba(239, 71, 111, 0.1);
    color: #ef476f;
    border-radius: 0 0 4px 4px;
}

.panel-layout
.panel-header
.nav-wrap
.nav-right
.single-nav-right
.single-right
.dropdown-menu
li.logout
.dropdown-item:hover {
    background: #ef476f;
    color: #ffffff;
}

.panel-layout .side-nav {
    width: 400px;
    background-color: #bee3db;
    position: relative;
    z-index: 1000;
    top: 70px;
    bottom: 0px;
    position: fixed;
    overflow: auto;
    border-right: 1px solid #dddddd85;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
}

@media (max-width: 991px) {
    .panel-layout .side-nav {
        width: 0;
    }
}

.panel-layout .side-nav .side-nav-inside {
    position: relative;
    height: 100%;
}

.panel-layout .side-nav .side-nav-inside .side-nav-menu {
    position: relative;
    list-style: none;
    margin: 0;
    padding-left: 0;
    overflow: auto;
    height: calc(100vh - 70px);
    padding: 30px;
}

.panel-layout .side-nav .side-nav-inside .side-nav-menu .side-nav-item {
    position: relative;
    display: block;
    border-radius: 6px;
    transition: 0.4s;
    margin-bottom: 10px;
}

.panel-layout .side-nav .side-nav-inside .side-nav-menu .side-nav-item a {
    position: relative;
    display: block;
    padding: 10px 18px;
    white-space: nowrap;
    color: rgba(0, 18, 25, 0.7);
    border-radius: 50px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    z-index: 1;
    transition: 0.4s;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item
a::after {
    position: absolute;
    content: "";
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0px;
    border-radius: 50px;
    background: rgba(0, 48, 73, 0.1);
    width: 0px;
    transition: 0.4s;
    z-index: -1;
}

.panel-layout .side-nav .side-nav-inside .side-nav-menu .side-nav-item a i {
    margin-right: 10px;
    font-size: 18px;
}

.panel-layout .side-nav .side-nav-inside .side-nav-menu .side-nav-item a:hover {
    color: #003049;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item
a:hover::after {
    width: 100%;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.active
a {
    background: #535a94;
    color: #ffffff;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.active
a::after {
    display: none;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown
.dropdown-items:not(.show) {
    display: none;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown
.dropdown-items
li {
    margin-bottom: 5px;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown
.dropdown-items
li
a {
    font-size: 12px;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown
.dropdown-items
li
a
svg {
    height: 16px;
    margin-right: 15px;
    position: relative;
    left: 10px;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown
.dropdown-items
li.active
a {
    background: #e73667;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown
.dropdown-link {
    position: relative;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown
.dropdown-link
.right-arrow {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.1s;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown.show
.dropdown-link
.right-arrow {
    top: 20%;
    transform: scaleY(-1);
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown.show
.dropdown-items {
    display: block;
    background: #2b3457;
    border-radius: 4px;
}

.panel-layout
.side-nav
.side-nav-inside
.side-nav-menu
.side-nav-item.category-title {
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.3);
    position: relative;
    display: block;
    padding: 10px 12px 0 12px;
    white-space: nowrap;
    margin-bottom: 5px;
    font-weight: 700;
}

.panel-layout .page-container {
    min-height: 100vh;
    padding-left: 400px;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
}

@media (max-width: 991px) {
    .panel-layout .page-container {
        padding-left: 0;
    }
}

.panel-layout .page-container .main-content {
    padding: calc(70px + 0px) 10px 20px;
    min-height: calc(100vh - 70px);
}

.panel-layout .page-container .main-content .page-title {
    padding: 20px 0;
}

.panel-layout .page-container .main-content .page-title .title-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.panel-layout .page-container .main-content .page-title .title-content .title {
    font-size: 28px;
    margin-bottom: 0px;
    position: relative;
    display: inline-block;
}

@media (max-width: 500px) {
    .panel-layout
    .page-container
    .main-content
    .page-title
    .title-content
    .title {
        font-size: 20px;
    }
}

.panel-layout
.page-container
.main-content
.page-title
.title-content
.title-btn {
    display: inline-block;
    background: #e73667;
    border-radius: 3px;
    padding: 8px 18px;
    border: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.panel-layout
.page-container
.main-content
.page-title
.title-content
.title-btn
svg {
    height: 16px;
    margin-right: 3px;
    position: relative;
    top: -2px;
}

.panel-layout
.page-container
.main-content
.page-title
.title-content
.title-btn:hover {
    background: #e73667;
}

.panel-layout .page-container .main-content .section-title {
    margin-bottom: 10px;
}

.panel-layout .page-container .main-content .section-title .title {
    font-size: 18px;
    margin-bottom: 0px;
}

.panel-layout.nav-folded .logo {
    width: 0px;
    border-right: 0px !important;
}

@media (max-width: 991px) {
    .panel-layout.nav-folded .logo {
        width: 0;
    }
}

.panel-layout.nav-folded .logo a .logo-unfold {
    display: none;
}

.panel-layout.nav-folded .logo a .logo-fold {
    display: block;
}

.panel-layout.nav-folded .nav-wrap .nav-left .sidebar-toggle {
    transform: scaleX(-1);
}

.panel-layout.nav-folded .side-nav {
    width: 0px;
    border-right: 0;
}

.panel-layout.nav-folded .side-nav.side-nav-hover {
    width: 400px;
    transition: 0.2s;
}

.panel-layout.nav-folded
.side-nav.side-nav-hover
.side-nav-inside
.side-nav-menu
.side-nav-item
span {
    display: inline-block;
    transition: 0.2s;
}

.panel-layout.nav-folded
.side-nav.side-nav-hover
.side-nav-inside
.side-nav-menu
.side-nav-item.category-title {
    display: block;
    transition: 0.2s;
}

.panel-layout.nav-folded
.side-nav.side-nav-hover
.side-nav-inside
.side-nav-menu
.side-nav-item.side-nav-dropdown.show
.dropdown-items {
    display: block;
}

@media (max-width: 991px) {
    .panel-layout.nav-folded .side-nav {
        width: 400px;
    }

    .panel-layout.nav-folded
    .side-nav
    .side-nav-inside
    .side-nav-menu
    .side-nav-item
    span {
        display: inline-block;
        transition: 0.2s;
    }

    .panel-layout.nav-folded
    .side-nav
    .side-nav-inside
    .side-nav-menu
    .side-nav-item.category-title {
        display: block;
        transition: 0.2s;
    }
}

@media (max-width: 400px) {
    .panel-layout.nav-folded .side-nav {
        width: 360px;
    }
}

@media (max-width: 350px) {
    .panel-layout.nav-folded .side-nav {
        width: 320px;
    }
}

@media (max-width: 320px) {
    .panel-layout.nav-folded .side-nav {
        width: 310px;
    }
}

.panel-layout.nav-folded .page-container {
    padding-left: 0px;
    transition: 0.2s;
}

@media (max-width: 991px) {
    .panel-layout.nav-folded .page-container {
        padding-left: 0px;
    }
}

@media (max-width: 991px) {
    .panel-layout.nav-folded .nav-wrap .nav-left .sidebar-toggle {
        transform: scaleX(1);
    }
}

/* Wallet List Design */
.side-wallet-box {
    padding: 15px;
    margin-bottom: 30px;
    background: #bee3db;
    box-shadow: 0px 0px 2px #7366f066;
}

.side-wallet-box .user-balance-card {
    padding: 5px 5px 15px 5px;
    border-radius: 4px;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
}

.side-wallet-box .user-balance-card::before {
    position: absolute;
    height: 90px;
    width: 90px;
    content: "";
    border-radius: 50%;
    background: rgba(231, 54, 103, 0.2);
    right: -50px;
    top: -50px;
}

.side-wallet-box .user-balance-card .wallet-name {
    margin-bottom: 10px;
    position: relative;
}

.side-wallet-box .user-balance-card .wallet-name .name {
    font-size: 18px;
    font-weight: 400;
}

.side-wallet-box .user-balance-card .wallet-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
}

.side-wallet-box .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}

.side-wallet-box .actions .user-sidebar-btn {
    width: 48%;
    background: #5364f1;
    padding: 10px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
}

.side-wallet-box .actions .user-sidebar-btn:last-of-type {
    background: #2a9d8f;
    color: #ffffff;
    border-radius: 4px;
}

.side-wallet-box .actions .user-sidebar-btn:last-of-type:hover {
    background: #e73667;
}

.side-wallet-box .actions .user-sidebar-btn i {
    margin-right: 6px;
}

.side-wallet-box .actions .user-sidebar-btn:hover {
    background: #e73667;
}

.side-wallet-box.default-wallet {
    padding: 20px;
    box-shadow: 0px 0px 2px #7366f066;
}

.side-wallet-box.default-wallet .user-balance-card {
    background: linear-gradient(90deg, #e52e71, #ff8a00);
    padding: 15px;
    margin-bottom: 10px;
}

.side-wallet-box.default-wallet .user-balance-card::before {
    display: none;
}

.side-wallet-box.default-wallet .user-balance-card .wallet-name {
    margin-bottom: 15px;
}

.side-wallet-box.default-wallet .user-balance-card .wallet-name .name {
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
}

.side-wallet-box.default-wallet .user-balance-card .wallet-name .default {
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 5px 10px;
    font-size: 10px;
    text-transform: uppercase;
    background: #ffffff;
    color: #001219;
    border-radius: 4px;
    font-weight: 700;
}

.side-wallet-box.default-wallet .user-balance-card .wallet-info {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.side-wallet-box.default-wallet .user-balance-card .wallet-info:last-child {
    margin-bottom: 0;
}

.side-wallet-box.default-wallet .user-balance-card .wallet-info .wallet-id {
    color: rgba(255, 255, 255, 0.8);
}

.side-wallet-box.default-wallet .user-balance-card .wallet-info .wallet-id svg {
    height: 18px;
    margin-right: 4px;
    position: relative;
    top: -2px;
}

.side-wallet-box.default-wallet .user-balance-card .wallet-info .balance {
    color: #ffffff;
}

/* User Cards */
.user-cards {
    margin-bottom: 20px;
}

.user-cards .single {
    background: #535a94;
    padding: 20px 15px;
    border-radius: 8px;
    border: 2px solid rgba(255, 195, 0, 0.5);
    margin-bottom: 30px;
    transition: 0.4s;
    cursor: default;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.user-cards .single::before {
    position: absolute;
    height: 65px;
    width: 65px;
    content: "";
    border-radius: 50%;
    background: rgba(255, 195, 0, 0.5);
    right: -25px;
    top: -25px;
}

.user-cards .single:hover {
    transform: translate3d(0, -4px, 0);
}

.user-cards .single .icon {
    font-size: 24px;
    height: 45px;
    width: 45px;
    line-height: 45px;
    border-radius: 50%;
    text-align: center;
    background: #ffffff;
    color: #535a94;
    display: inline-block;
    margin-right: 15px;
}

.user-cards .single .content h4 {
    margin-bottom: 5px;
    color: #ffffff;
}

.user-cards .single .content h4 b {
    font-weight: 400;
    font-size: 16px;
    position: relative;
    top: -8px;
    margin-right: 2px;
}

.user-cards .single .content p {
    margin-bottom: 0;
    color: rgba(255, 255, 255, 0.9);
}

/* site-transactions */
.site-transactions .single {
    padding: 15px;
    box-shadow: 0px 0px 2px #5468ff66;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.3s;
}

.site-transactions .single.head {
    background: #d2d3d8;
    padding: 5px 15px;
    font-weight: 700;
}

.site-transactions .single.head:hover {
    background: #d2d3d8;
}

.site-transactions .single .left {
    display: flex;
    min-width: 300px;
}

@media (max-width: 767px) {
    .site-transactions .single .left {
        min-width: 300px;
    }
}

.site-transactions .single .left .icon {
    height: 45px;
    width: 45px;
    line-height: 42px;
    border-radius: 50%;
    background: rgba(0, 48, 73, 0.1);
    color: #003049;
    text-align: center;
    margin-right: 15px;
}

.site-transactions .single .left .icon svg {
    width: 18px;
}

.site-transactions .single .left .icon-img img {
    height: 30px;
    width: 30px;
    background: rgba(0, 48, 73, 0.1);
    color: #003049;
    text-align: center;
    margin-right: 15px;
}

.site-transactions .single .left .content .title {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.site-transactions .single .left .content .date {
    font-size: 14px;
    margin-bottom: 0;
    color: rgba(0, 18, 25, 0.5);
}

.site-transactions .single .middle {
    display: flex;
    align-items: center;
    font-size: 16px;
}

@media (max-width: 767px) {
    .site-transactions .single .middle {
        min-width: 250px;
    }
}

.site-transactions .single .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media (max-width: 767px) {
    .site-transactions .single .right {
        min-width: 300px;
    }
}

.site-transactions .single .right .amount {
    text-align: right;
}

.site-transactions .single .right .amount .net {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.site-transactions .single .right .amount .net.in {
    color: #2a9d8f;
}

.site-transactions .single .right .amount .net.out {
    color: #ef476f;
}

.site-transactions .single .right .amount .total {
    font-size: 14px;
}

.site-transactions .single .right .action {
    margin-left: 50px;
}

.site-transactions .single .right .action a {
    margin-left: 10px;
    height: 40px;
    width: 40px;
    line-height: 38px;
    border-radius: 50%;
    background: #535a94;
    color: #ffffff;
    text-align: center;
    display: inline-block;
}

.site-transactions .single .right .action a svg {
    height: 16px;
    position: relative;
    top: -3px;
}

.site-transactions .single .right .action a.cancel {
    background: #ef476f;
}

.site-transactions .single .right .action a.disabled {
    opacity: 0.4;
    pointer-events: none;
}

.site-transactions .single .right .action a:hover {
    background: #003049;
}

.site-transactions .single .right .action a.delete {
    background: #ef476f;
}

.site-transactions .single:hover {
    background: rgba(231, 54, 103, 0.03);
}

.site-transactions .single:first-child {
    border-radius: 4px 4px 0 0;
}

.site-transactions .single:last-child {
    border-radius: 0px 0px 4px 4px;
}

@media (max-width: 855px) {
    .site-transactions .single {
        min-width: 900px;
    }
}

@media (max-width: 855px) {
    .site-transactions {
        display: block;
        overflow-x: auto;
        width: 100%;
    }

    .site-transactions::-webkit-scrollbar {
        height: 4px;
        width: 4px;
    }

    .site-transactions::-moz-scrollbar {
        height: 4px;
        width: 4px;
    }
}

/* Invoice */
.invoice-option-btn {
    display: inline-block;
    background: #e73667;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 37px;
}

.invoice-option-btn.remove-optionss {
    background: #ef476f;
    margin-top: 5px;
}

.invoice-option-btn.disabled {
    pointer-events: none;
    opacity: 0.65;
}

/* chatting */
.chatting-content {
    height: 500px;
    overflow-y: scroll;
    padding-right: 15px;
    padding-bottom: 40px;
}

.chatting-content::-webkit-scrollbar {
    width: 5px;
}

.chatting-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 48, 73, 0.3);
}

.chatting-content::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 18, 25, 0.3);
}

.chatting-content .identity {
    color: #ffffff57;
    font-size: 12px;
}

.chatting-content .user-chat,
.chatting-content .admin-chat {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    font-size: 14px;
}

.chatting-content .user-chat {
    text-align: right;
}

.chatting-content .user-chat .content {
    display: inline-block;
    background: #ffc300;
    padding: 8px 16px;
    border-radius: 6px;
    color: #001219;
}

.chatting-content .admin-chat .content {
    display: inline-block;
    background: #003049;
    padding: 8px 16px;
    border-radius: 6px;
    color: #ffffff;
}

.chatting .chat-box {
    position: relative;
    overflow: hidden;
}

.chatting .chat-box textarea.message-box {
    width: calc(100% - 210px);
    margin-left: 80px;
    height: 60px;
    float: left;
    box-shadow: 0px 0px 9px 1px #fd4d401a;
    font-size: 16px;
    padding: 15px;
    padding-top: 10px;
    border: 2px solid rgba(0, 18, 25, 0.1);
    border-radius: 8px;
    color: #001219;
    background: transparent;
    outline: none;
}

.chatting .chat-box textarea.message-box:focus {
    border: 2px solid rgba(231, 54, 103, 0.3);
}

.chatting .chat-box button {
    position: absolute;
    top: 0px;
    right: 4px;
    width: 100px;
    height: 60px;
    color: #ffffff;
    background: #e73667;
    border-radius: 8px;
    font-size: 18px;
}

.chatting .chat-box .attach-file {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #ffffff;
    background: #e73667;
    border-radius: 50%;
    font-size: 18px;
    display: inline-block;
    text-align: center;
}

.chatting .chat-box .attach-file label {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
}

.chatting .chat-box .attach-file input {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px;
    height: 60px;
    line-height: 60px;
    opacity: 0;
    cursor: pointer;
}

/* access-device */
.access-device {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    background: #5468ff17;
    padding: 5px 10px;
    border-radius: 10px;
}

.access-device .icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.access-device .icon img {
    height: 40px;
    width: auto;
}

.access-device .content p {
    font-size: 14px;
    margin-bottom: 2px;
}

.access-device .content p .this-device {
    margin-left: 10px;
    color: #2a9d8f;
}

.access-device .content p .time {
    margin-left: 10px;
}


/* Site Normal Table */
.site-table {
    margin-bottom: 30px;
    box-shadow: 0px 0px 2px #aab0c6;
}

.site-table .table-filter {
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
}

.site-table .table-filter::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

.site-table .table-filter::-moz-scrollbar {
    height: 4px;
    width: 4px;
}

.site-table .table-filter .filter {
    align-items: center;
    display: flex;
}

.site-table .table-filter .filter select {
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 10px;
    border: 1px solid rgba(210, 211, 216, 0.7);
}

@media (max-width: 800px) {
    .site-table .table-filter .filter select {
        min-width: 130px;
    }
}

.site-table .table-filter .filter select option {
    font-weight: 500;
}

.site-table .table-filter .filter select:focus {
    box-shadow: none;
    border: 1px solid rgba(94, 63, 201, 0.5);
}

.site-table .table-filter .filter select.show {
    width: 100px;
}

.site-table .table-filter .filter .apply-btn {
    display: inline-block;
    background: #e73667;
    border-radius: 3px;
    padding: 4px 20px;
    border: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    min-width: 116px;
}

.site-table .table-filter .filter .apply-btn svg {
    width: 14px;
    margin-right: 5px;
    position: relative;
    top: -1px;
}

.site-table .table-filter .filter .apply-btn:hover {
    background: #535a94;
}

.site-table .table-filter .filter .search {
    display: flex;
    align-items: center;
}

.site-table .table-filter .filter .search label {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #151d30;
    margin-right: 5px;
}

.dark-theme .site-table .table-filter .filter .search label {
    color: #ffffff;
}

.site-table .table-filter .filter .search input {
    height: 30px;
    border: 1px solid rgba(210, 211, 216, 0.7);
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 3px;
    margin-right: 10px;
    min-width: 180px;
    color: #333333;
    background: transparent;
}

.dark-theme .site-table .table-filter .filter .search input {
    border: 1px solid rgba(210, 211, 216, 0.7);
    color: #ffffff;
    background: transparent;
}

.site-table .table-filter .filter .search input:focus {
    box-shadow: none;
    border: 1px solid rgba(94, 63, 201, 0.5);
}

.dark-theme .site-table .table-filter .filter .search input:focus {
    box-shadow: none;
    border: 1px solid #e73667;
}

.site-table .table-filter .filter .filter-right-btn button {
    display: inline-block;
    margin-left: 20px;
    color: #101935;
    background: transparent;
    line-height: 1;
    transition: background-color 0.3s;
    margin-bottom: 5px;
}

.dark-theme .site-table .table-filter .filter .filter-right-btn button {
    color: #ffffff;
}

.site-table .table-filter .filter .filter-right-btn button svg {
    height: 18px;
}

.site-table .table-filter .filter .filter-right-btn .dropdown-menu {
    width: 350px;
    margin-top: 8px !important;
    padding: 20px;
}

.site-table .table-filter .filter .filter-right-btn .dropdown-menu .form-check {
    margin-bottom: 10px;
}

.site-table .table-filter .filter .filter-right-btn .dropdown-menu .form-check .form-check-input:checked {
    background-color: #5e3fc9;
    border-color: #5e3fc9;
}

.site-table .table-filter .filter .filter-right-btn .dropdown-menu .form-check .form-check-input:focus {
    box-shadow: none;
}

.site-table .table-filter .filter .filter-right-btn .dropdown-menu .form-check .form-check-label {
    font-size: 14px;
    font-weight: 700;
}

.site-table .table {
    width: 100%;
    margin-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 2px #aab0c6;
    min-width: 1200px;
}


.dark-theme .site-table .table {
    background: #003049;
    box-shadow: 0px 0px 2px #aab0c6;
}

.site-table .table thead tr th {
    border-bottom: 1px solid rgba(83, 90, 148, 0.2);
    padding: 15px 10px;
    color: #003049;
    background: rgba(83, 90, 148, 0.1);
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
}

.dark-theme .site-table .table thead tr th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.1);
}

.site-table .table tbody {
    border-top: 0px;
}

.site-table .table tbody tr {
    transition: 0.3s;
}

.site-table .table tbody tr td {
    border-bottom: 1px solid rgba(83, 90, 148, 0.1);
    padding: 15px 10px;
    color: #003049;
    justify-content: center;
    font-size: 14px;
    line-height: 2.7;
}

.dark-theme .site-table .table tbody tr td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.site-table .table tbody tr td .table-description {
    display: flex;
    align-items: center;
}

.site-table .table tbody tr td .table-description .icon {
    height: 45px;
    width: 45px;
    line-height: 42px;
    border-radius: 50%;
    background: rgba(210, 211, 216, 0.7);
    color: #001219;
    text-align: center;
    margin-right: 15px;
}

.dark-theme .site-table .table tbody tr td .table-description .icon {
    background: rgba(83, 90, 148, 0.7);
    color: #ffffff;
}

.site-table .table tbody tr td .table-description .icon svg {
    height: 18px;
}

.site-table .table tbody tr td .table-description .description {
    font-size: 16px;
}

.site-table .table tbody tr td .table-description .description .date {
    margin-top: -12px;
    font-size: 13px;
    color: rgba(0, 18, 25, 0.75);
}

.dark-theme .site-table .table tbody tr td .table-description .description .date {
    color: rgba(255, 255, 255, 0.75);
}

.site-table .table tbody tr td .table-description .description .date .time {
    margin-left: 5px;
}

.site-table .table tbody tr td .optional-msg {
    color: #ffffff;
    height: 25px;
    width: 25px;
    line-height: 21px;
    display: inline-block;
    background: #e73667;
    border-radius: 50%;
    margin-left: 5px;
    cursor: pointer;
}

.site-table .table tbody tr td .optional-msg svg {
    height: 13px;
}

.site-table .table tbody tr td .avatar-img img {
    height: 40px;
    width: 40px;
}

.site-table .table tbody tr td .date {
    margin-top: -12px;
    font-size: 13px;
    color: rgba(0, 18, 25, 0.75);
}

.dark-theme .site-table .table tbody tr td .date {
    color: rgba(255, 255, 255, 0.75);
}

.site-table .table tbody tr td .investment-timeline {
    background: #d2d3d8;
    height: 0.5rem;
}

.site-table .table tbody tr td .investment-timeline .progress-bar {
    background: #ef476f;
}


@media (max-width: 991px) {
    .site-table .table {
        min-width: 1200px;
    }

    .site-table .table::-webkit-scrollbar {
        height: 4px;
        width: 4px;
    }
}


/* Site pagination */
.site-pagination {
    padding: 0 15px 15px 15px;
}

.site-pagination .page-item.active .page-link {
    background: #e73667;
    color: #ffffff;
    border-color: #e73667;
}

.site-pagination .page-item .page-link {
    color: #151d30;
    font-size: 14px;
    font-weight: 700;
}


/* Site DataTable */
.site-datatable .dataTables_length {
    margin-bottom: 15px;
}

.site-datatable .dataTables_length label {
    font-size: 14px;
    font-weight: 700 !important;
}

.site-datatable .dataTables_length label select {
    font-weight: 700 !important;
}

.site-datatable .dataTables_length label select:focus {
    box-shadow: none;
    border: 1px solid rgba(231, 54, 103, 0.5);
}

.site-datatable .dataTables_filter {
    margin-bottom: 15px;
}

.site-datatable .dataTables_filter label {
    font-size: 14px;
    font-weight: 700 !important;
}

.site-datatable .dataTables_filter label input {
    font-weight: 700 !important;
}

.site-datatable .dataTables_filter label input:focus {
    box-shadow: none;
    border: 1px solid rgba(231, 54, 103, 0.5);
}

.site-datatable .dataTables_info {
    font-size: 14px;
    font-weight: 700;
}

.site-datatable .dataTables_paginate {
    margin-top: 10px;
}

.site-datatable .dataTables_paginate .pagination .paginate_button .page-link {
    color: #001219;
    font-size: 14px;
    font-weight: 700;
}

.site-datatable
.dataTables_paginate
.pagination
.paginate_button
.page-link:focus {
    box-shadow: none;
}

.site-datatable
.dataTables_paginate
.pagination
.paginate_button.active
.page-link {
    background: #e73667;
    color: #ffffff;
    border-color: #e73667;
}

.site-datatable .data-table {
    width: 100%;
    margin-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 2px #aab0c6;
    min-width: 1200px;
}

@media (max-width: 991px) {
    .site-datatable .data-table {
        min-width: 1200px;
    }
}

.site-datatable .data-table thead tr th {
    border-bottom: 1px solid rgba(83, 90, 148, 0.2);
    padding: 15px 10px;
    color: #003049;
    background: rgba(83, 90, 148, 0.1);
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
}

.site-datatable .data-table thead tr th::before {
    top: 50%;
    transform: translateY(-50%);
}

.site-datatable .data-table thead tr th::after {
    top: 50%;
    transform: translateY(-50%);
}

.site-datatable .data-table tbody {
    border-top: 0;
}

.site-datatable .data-table tbody tr {
    transition: 0.3s;
}

.site-datatable .data-table tbody tr td {
    border-bottom: 1px solid rgba(83, 90, 148, 0.1);
    padding: 15px 10px;
    color: #003049;
    justify-content: center;
    font-size: 14px;
    line-height: 2.7;
}

.site-datatable .data-table tbody tr td .avatar {
    width: 40px;
    border-radius: 50%;
}

.site-datatable .data-table tbody tr td .avatar-text {
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #ffffff;
}

.site-datatable .data-table tbody tr td .avatar-img img {
    height: 40px;
    width: 40px;
}

.site-datatable .data-table tbody tr td .form-switch input {
    box-shadow: none;
    height: 18px;
    width: 35px;
    margin-top: 10px;
}

.site-datatable
.data-table
tbody
tr
td
.form-switch
input.form-check-input:checked {
    background-color: #e73667;
    border-color: #e73667;
}

.site-datatable .data-table tbody tr td .site-badge {
    display: inline;
    color: #ffffff;
    padding: 3px 14px;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 700;
}

.site-datatable .data-table tbody tr td .site-badge.success {
    background: #2a9d8f;
}

.site-datatable .data-table tbody tr td .site-badge.failed {
    background: #ef476f;
}

.site-datatable .data-table tbody tr td .site-badge.warnning {
    background: #ffc300;
    color: #001219;
}

.site-datatable .data-table tbody tr td .transaction {
    text-transform: uppercase;
    font-size: 12px;
}

.site-datatable .data-table tbody tr td .link {
    color: #e73667;
    font-weight: 700;
}

.site-datatable .data-table tbody tr td .link:hover {
    color: #2a9d8f;
}

.site-datatable .data-table tbody tr td .clock {
    margin-left: 5px;
}

.site-datatable .data-table tbody tr td .investment-timeline {
    background: #d2d3d8;
    height: 0.5rem;
}

.site-datatable .data-table tbody tr td .investment-timeline .progress-bar {
    background: #ef476f;
}

.site-datatable .data-table tbody tr td .table-description {
    display: flex;
    align-items: center;
}

.site-datatable .data-table tbody tr td .table-description .icon {
    height: 45px;
    width: 45px;
    line-height: 42px;
    border-radius: 50%;
    background: rgba(210, 211, 216, 0.7);
    color: #001219;
    text-align: center;
    margin-right: 15px;
}

.site-datatable .data-table tbody tr td .table-description .icon svg {
    height: 18px;
}

.site-datatable .data-table tbody tr td .table-description .description {
    font-size: 16px;
}

.site-datatable .data-table tbody tr td .date {
    margin-top: -12px;
    font-size: 13px;
    color: rgba(0, 18, 25, 0.75);
}

.site-datatable .data-table tbody tr td .date .time {
    margin-left: 5px;
}

.site-datatable .data-table tbody tr td .optional-msg {
    color: #ffffff;
    height: 25px;
    width: 25px;
    line-height: 22px;
    display: inline-block;
    background: #e73667;
    border-radius: 50%;
    margin-left: 5px;
    cursor: pointer;
}

.site-datatable .data-table tbody tr td .optional-msg svg {
    height: 13px;
}

.site-datatable .data-table tbody tr:hover {
    background: rgba(83, 90, 148, 0.05);
}

.site-datatable .data-table tbody tr:nth-of-type(4n + 1) td .avatar-text {
    background: #e73667;
}

.site-datatable .data-table tbody tr:nth-of-type(4n + 2) td .avatar-text {
    background: #001219;
}

.site-datatable .data-table tbody tr:nth-of-type(4n + 3) td .avatar-text {
    background: #535a94;
}

.site-datatable .data-table tbody tr:nth-of-type(4n + 4) td .avatar-text {
    background: #2a9d8f;
}

.site-datatable .data-table tbody tr:nth-of-type(4n + 5) td .avatar-text {
    background: #003049;
}

.site-datatable .data-table tbody tr:nth-of-type(4n + 6) td .avatar-text {
    background: #e73667;
}

.site-datatable .data-table tbody tr:nth-of-type(4n + 6) td .avatar-text {
    background: #535a94;
}

/* single-badge */
.single-badge {
    text-align: center;
    border: 2px solid rgba(0, 18, 25, 0.1);
    border-radius: 50%;
    padding: 50px;
    margin: 0 auto;
    margin-bottom: 30px;
    height: 270px;
    width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    position: relative;
    background: rgba(83, 90, 148, 0.3);
}

.single-badge .badge {
    margin-bottom: 10px;
}

.single-badge .badge .img {
    width: 80px;
}

.single-badge .content .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.single-badge .content .description {
    color: rgba(0, 18, 25, 0.6);
    font-size: 14px;
    margin-bottom: 0;
}

.single-badge.locked::before {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 100%;
    content: "";
    background: #001219;
    opacity: 0.8;
    transition: 0.4s;
}

.single-badge.locked::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "\f023";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: rgba(255, 255, 255, 0.7);
    font-size: 62px;
    transition: 0.4s;
}

.single-badge:hover.locked::before {
    display: none;
}

.single-badge:hover.locked::after {
    display: none;
}

/* support-ticket-single-message */
.support-ticket-single-message {
    box-shadow: 0px 0px 2px rgba(0, 48, 73, 0.4);
    background: #21273e08;
    margin-bottom: 40px;
    padding: 30px;
    width: 65%;
    border-radius: 10px;
    font-size: 14px;
}

@media (max-width: 1024px) {
    .support-ticket-single-message {
        width: 100%;
    }
}

.support-ticket-single-message.admin {
    float: left;
}

.support-ticket-single-message.user {
    float: right;
}

.support-ticket-single-message .logo {
    margin-bottom: 40px;
}

/* .support-ticket-single-message .logo img {
    width: 40px;
    border-radius: 50%;
} */

.support-ticket-single-message .salutation {
    margin-bottom: 20px;
}

.support-ticket-single-message .message-body .article {
    margin-bottom: 25px;
}

.support-ticket-single-message .message-footer .regards {
    margin-bottom: 10px;
}

.support-ticket-single-message .message-footer .name {
    font-weight: 700;
}

.support-ticket-single-message .message-footer .email a {
    color: #e73667;
}

.support-ticket-single-message .message-attachments {
    margin-top: 30px;
}

.support-ticket-single-message .message-attachments .title {
    font-style: italic;
    font-size: 14px;
    margin-bottom: 10px;
}

.support-ticket-single-message
.message-attachments
.single-attachment
.attach
a {
    color: #001219;
}

.support-ticket-single-message
.message-attachments
.single-attachment
.attach
a:hover {
    color: #e73667;
}

.support-ticket-single-message
.message-attachments
.single-attachment
.attach
a
i {
    margin-right: 5px;
}

/* Site Tab Bars */
.site-tab-bars {
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: 0px 0px 2px rgba(231, 54, 103, 0.4);
    background: white;
    border-radius: 4px;
}

.site-tab-bars ul li {
    display: inline-block;
}

@media (max-width: 991px) {
    .site-tab-bars ul li {
        margin-bottom: 10px;
        margin-right: 10px;
    }
}

.site-tab-bars ul li a {
    color: #003049;
    font-weight: 700;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 4px;
}

.site-tab-bars ul li a svg {
    width: 16px;
    position: relative;
    margin-right: 5px;
    top: -1px;
}

.site-tab-bars ul li a:hover {
    color: #e73667;
}

@media (max-width: 575px) {
    .site-tab-bars ul li a {
        background: rgba(231, 54, 103, 0.2);
        display: block;
    }
}

.site-tab-bars ul li.active a {
    background: #e73667;
    color: #ffffff;
}

.site-tab-bars .nav.nav-pills {
    box-shadow: none;
}

.site-tab-bars .nav.nav-pills .nav-item .nav-link {
    font-weight: 700;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 4px;
    color: rgba(0, 48, 73, 0.6);
}

.site-tab-bars .nav.nav-pills .nav-item .nav-link.active {
    border-bottom: none;
    background: #e73667;
    color: #ffffff;
}

/* Automatic Popup */
.auto-popup-section {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease 0s;
    background-color: rgba(63, 58, 100, 0.9);
}

.auto-popup-section:not(.close) .auto-popup-dialog {
    animation-duration: 0.5s;
    animation-delay: 0.3s;
}

.auto-popup-section.close {
    visibility: hidden;
    opacity: 0;
}

.auto-popup-section.close .auto-popup-dialog {
    animation-duration: 0.5s;
    animation-delay: 0;
}

.auto-popup-dialog {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    width: 650px;
    max-width: 90vw;
    max-height: 100vh;
    border-radius: 8px;
    background-color: #fff;
}

.auto-popup-close {
    line-height: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    color: #ccc;
    border: none;
    background-color: transparent;
    z-index: 2;
}

.auto-popup-close i {
    font-size: 32px;
    line-height: 1;
}

.auto-popup-close:hover {
    color: #ef476f;
}

@media only screen and (max-width: 479px) {
    .auto-popup-close {
        top: 10px;
        right: 10px;
    }
}

.auto-popup-dialog-inner {
    padding: 50px;
    background-size: cover !important;
    position: relative;
    z-index: 1;
}

.auto-popup-dialog-inner::before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    left: 0;
    top: 0;
    background: #2a9d8f;
    opacity: 0.7;
    z-index: -1;
}

.auto-pop-content {
    text-align: center;
}

.auto-pop-content h2 {
    font-size: 52px;
    font-weight: 700;
    margin-bottom: 20px;
}

.auto-pop-content h3 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
    .auto-popup-dialog-inner {
        padding: 40px 30px;
    }
}

@media only screen and (max-width: 479px) {
    .auto-popup-dialog-inner {
        padding: 40px 20px;
    }

    .auto-pop-content h2 {
        font-size: 24px;
    }

    .auto-pop-content h3 {
        font-size: 18px;
    }
}

/* caches-privacy */
.caches-privacy {
    position: fixed;
    bottom: 30px;
    left: 30px;
    width: 430px;
    border-radius: 8px;
    padding: 30px;
    z-index: 1;
    background: #ffffff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 0px 2px rgba(0, 48, 73, 0.8);
}

@media (max-width: 575px) {
    .caches-privacy {
        width: 94%;
        left: 3%;
        right: 3%;
    }
}

.caches-privacy .content p a {
    color: #ffc300;
}

.site-badge {
    display: inline;
    color: #ffffff;
    padding: 3px 14px;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 700;
}

.site-badge.success {
    background: #2a9d8f;
}

.site-badge.failed {
    background: #ef476f;
}

.site-badge.warnning {
    background: #ffc300;
    color: #001219;
}

.dark-theme .site-sidebar .single-sidebar {
    color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 0.2);
}

.dark-theme .site-sidebar .single-sidebar form input {
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.2);
    color: #ffffff;
}

.dark-theme .blog-details {
    color: rgba(255, 255, 255, 0.9);
}

.dark-theme .blog-details .blockquote {
    color: #ffffff;
}

.dark-theme .post-share-and-tag .social {
    color: #ffffff;
}

.dark-theme .post-share-and-tag .social a {
    color: #ffffff;
}

.user-home-menu {
    display: none !important;
    padding: 8px 12px;
    line-height: 1;
    border-radius: 4px;
    background: #e73667;
    color: #ffffff;
}

.user-home-menu:focus {
    color: #ffffff;
}

@media (max-width: 992px) {
    .user-home-menu {
        display: block !important;
    }
}

@media (max-width: 575px) {
    .preview-table {
        width: 600px;
    }
}

@media (max-width: 400px) {
    .preview-table {
        width: 500px;
    }
}

/* frontend-editor-data */
.frontend-editor-data h1 {
    font-size: 42px;
    margin-bottom: 15px;
}

.frontend-editor-data h2 {
    font-size: 32px;
    margin-bottom: 15px;
}

.frontend-editor-data h3 {
    font-size: 26px;
    margin-bottom: 15px;
}

.frontend-editor-data h4 {
    font-size: 22px;
    margin-bottom: 10px;
}

.frontend-editor-data h5 {
    font-size: 18px;
    margin-bottom: 15px;
}

.frontend-editor-data h6 {
    font-size: 16px;
    margin-bottom: 15px;
}

.frontend-editor-data p {
    margin-bottom: 20px;
}

.frontend-editor-data ul,
.frontend-editor-data ol {
    margin: initial;
    padding: initial;
    list-style: initial;
    padding-left: 2rem;
    margin-bottom: 20px;
}

.frontend-editor-data ul li {
    margin-bottom: 10px;
}

@media (max-width: 1199px) {
    .site-sidebar {
        margin-top: 30px;
    }
}

/* input-icon */
.input-icon {
    position: absolute;
    right: 15px;
    top: 9px;
    color: #44444473;
}

.input-icon svg {
    height: 20px;
}

.dark-theme .input-icon {
    color: #ffffff73;
}

/* input-icon */
.input-icon {
    position: absolute;
    right: 15px;
    top: 9px;
    color: #44444473;
}

.input-icon svg {
    height: 20px;
}

.dark-theme .input-icon {
    color: #ffffff73;
}

/* Multi Level Referral Css */
.hv-wrapper {
    display: flex;
}

.hv-wrapper .hv-item {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.hv-wrapper .hv-item .hv-item-parent {
    margin-bottom: 50px;
    position: relative;
    display: flex;
    justify-content: center;
}

.hv-wrapper .hv-item .hv-item-parent:after {
    position: absolute;
    content: "";
    width: 2px;
    height: 25px;
    bottom: 0;
    left: 50%;
    background-color: #7c7474b3;
    transform: translateY(100%);
}

.hv-wrapper .hv-item .hv-item-children {
    display: flex;
    justify-content: center;
}

.hv-wrapper .hv-item .hv-item-parent:last-child:after {
    display: none;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child {
    padding: 0 15px;
    position: relative;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:before,
.hv-wrapper .hv-item .hv-item-children .hv-item-child:not(:only-child):after {
    content: "";
    position: absolute;
    background-color: #7c7474b3;
    left: 0;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:before {
    left: 50%;
    top: 0;
    transform: translateY(-100%);
    width: 2px;
    height: 25px;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:after {
    top: -25px;
    transform: translateY(-100%);
    height: 2px;
    width: 100%;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:first-child:after {
    left: 50%;
    width: 50%;
}

.hv-wrapper .hv-item .hv-item-children .hv-item-child:last-child:after {
    width: calc(50% + 1px);
}

.hv-item-parent p {
    font-weight: bold;
    color: #de5454;
}

.management-hierarchy {
    overflow-x: auto;
    margin-bottom: 20px;
}

.management-hierarchy .person {
    text-align: center;
}

.management-hierarchy .person > img {
    height: 50px;
    width: 50px;
    border: 3px solid #5364f1;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
}

.management-hierarchy .person > .f-name-l-name {
    height: 50px;
    width: 50px;
    line-height: 43px;
    border: 3px solid #5364f1;
    border-radius: 50%;
    overflow: hidden;
    background-color: #e73667;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: -6px;
}

.management-hierarchy .person > p.name {
    background-color: #5364f1;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: normal;
    color: #fff;
    margin: 0;
    position: relative;
}

.management-hierarchy .person > p.name b {
    color: #fff;
}

.single-investment-plan .holidays {
    font-size: 12px;
    font-weight: 300;
    color: #ffc300cc;
    min-height: 36px;
    margin-top: 15px;
}

.single-investment-plan .holidays .star {
    color: #ffc300cc;
}

/* Show in 575px in Mobile Screen */
.mobile-screen-show {
    display: none;
}

@media (max-width: 575px) {

    .desktop-screen-show {
        display: none;
    }

    .mobile-screen-show {
        display: block;
        position: relative;
    }

    .panel-layout .panel-header .nav-wrap .nav-left .sidebar-toggle {
        display: none;
    }

    .panel-layout .panel-header .nav-wrap .nav-left .mob-logo {
        display: block;
    }

    .mobile-screen-show .page-container {
        display: block;
    }

    .mobile-screen-show .main-content {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mobile-screen-show .section-gap {
        padding-top: 15px;
        padding-bottom: 45px;
    }

    .panel-layout
    .panel-header
    .nav-wrap
    .nav-right
    .single-nav-right
    .single-right
    .item {
        height: 34px;
        width: 34px;
        padding: initial;
        border-radius: 50%;
    }

    .user-ranking {
        display: none;
    }

    .user-ranking-mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #5d5fd3;
        padding: 5px;
        margin-bottom: 12px;
        border-radius: 10px;
    }

    .user-ranking-mobile .icon {
        width: 45px;
    }

    .user-ranking-mobile .icon img {
        border-radius: 10px;
    }

    .user-ranking-mobile .name {
        color: #fff;
        margin-left: 10px;
    }

    .user-ranking-mobile .name h4 {
        font-size: 16px;
        margin-bottom: 0;
    }

    .user-ranking-mobile .name p {
        margin-bottom: 0;
        font-size: 12px;
    }

    .user-ranking-mobile .rank-badge {
        width: 35px;
        height: 35px;
        background: #ffffff;
        border-radius: 50%;
        margin-left: auto;
        text-align: center;
        margin-right: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .user-ranking-mobile .rank-badge img {
        width: 18px;
    }

    .user-kyc-mobile {
        display: block;
        margin-bottom: 12px;
        font-size: 14px;
    }

    .user-kyc-mobile .kyc-star {
        color: #e5a32b;
        height: 16px;
        position: relative;
        top: -3px;
    }

    .user-kyc-mobile a {
        color: #e73667;
        border-bottom: 2px solid #e73667;
    }

    .user-wallets-mobile {
        display: block;
        border-radius: 10px;
        background-color: #4643fb;
        /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%231cffec' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%231dd4ff' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%231e97ff' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%231f5aff' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%232320FF' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23a22aff' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23ff34e7' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23ff3e7e' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23ff7148' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23FFD852' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E"); */
        background-size: cover;
        position: relative;
        padding: 15px;
        margin: 35px 10px;
    }

    .user-wallets-mobile .wallet-shadow {
        position: absolute;
        width: 70px;
        left: 50%;
        top: 50%;
        opacity: 0.15;
        z-index: 0;
        filter: grayscale(1);
        transform: rotate(0deg) translate(-50%, -50%);
    }

    .user-wallets-mobile::before {
        position: absolute;
        content: "";
        background: #c0ef6e;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 10px;
        z-index: -1;
        transform: rotate(8deg);
        opacity: 0.7;
    }

    .user-wallets-mobile::after {
        position: absolute;
        content: "";
        background: #f3d338;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 10px;
        z-index: -1;
        transform: rotate(4deg);
        opacity: 0.7;
    }

    .user-wallets-mobile .head {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        padding-bottom: 7px;
        border-bottom: 1px solid #ffffff24;
        color: #fff;
    }

    .user-wallets-mobile .info {
        font-size: 10px;
        font-weight: 400;
        color: #fff;
        margin-left: -5px;
        margin-top: 15px;
    }

    .user-wallets-mobile .info svg {
        height: 12px;
        position: relative;
        top: -2px;
    }

    .user-wallets-mobile .one {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        color: #fff;
    }

    .user-wallets-mobile .one .balance {
        font-size: 24px;
        letter-spacing: 1px;
    }

    .user-wallets-mobile .one .balance .after-dot {
        font-size: 18px;
    }

    .user-wallets-mobile .one .wallet {
        font-size: 14px;
    }

    .user-wallets-mobile .one:last-child {
        margin-bottom: 0;
    }

    .user-wallets-mobile .one.p-wal .balance {
        font-size: 18px;
    }

    .user-wallets-mobile .one.p-wal .balance .after-dot {
        font-size: 14px;
    }
}

.bottom-appbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    height: 60px;
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    z-index: 1;
    background: #5d5fd3;
    border-radius: 100px;
}

.bottom-appbar a {
    color: #75ffd26b;
    text-align: center;
}

.bottom-appbar a svg {
    height: 22px;
    margin-top: -5px;
}

.bottom-appbar a .lev {
    font-size: 10px;
    color: #ffffff57;
    margin-top: -1px;
    display: none;
}

.bottom-appbar .active {
    color: #2fe7ab;
}

.bottom-appbar .active .lev {
    color: #ffffff;
}

.mob-logo {
    display: none;
}

.mob-logo a img {
    width: 30px;
}

.all-cards-mobile .contents.row {
    margin-right: calc(-0.3 * var(--bs-gutter-x));
    margin-left: calc(-0.3 * var(--bs-gutter-x));
}

.all-cards-mobile .contents.row > * {
    padding-right: calc(var(--bs-gutter-x) * 0.3);
    padding-left: calc(var(--bs-gutter-x) * 0.3);
}

.all-cards-mobile .contents .single-card {
    background: #c0ef6e;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 10px 10px;
    color: #121111;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.all-cards-mobile .contents .single-card .icon {
    color: #ffffffa1;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background: #4f51f3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.all-cards-mobile .contents .single-card .icon svg {
    height: 24px;
    margin: auto;
}

.all-cards-mobile .contents .single-card .content .amount {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: -5px;
}

.all-cards-mobile .contents .single-card .content .name {
    font-size: 14px;
    font-weight: 500;
}

.all-feature-mobile {
    background-color: #ededed;
    background-size: cover;
    border-radius: 10px;
    padding: 10px 15px;
    border: 1px solid #00000021;
}

.all-feature-mobile .title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000021;
    padding-bottom: 10px;
    color: #3a3939;
}

.all-feature-mobile .contents .single {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.all-feature-mobile .contents .single a {
    color: #080707;
}

.all-feature-mobile .contents .single a .icon {
    margin: auto;
    margin-bottom: 5px;
    position: relative;
}

.moretext, .moretext-2 {
    display: none;
}

.all-feature-mobile .contents .single a .icon::after {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #ffc03491;
    top: -2px;
    left: 60%;
    transform: translateX(-50%);
    opacity: 0.8;
}

.all-feature-mobile .contents .single a .icon img {
    height: 23px;
    margin: auto;
}

.all-feature-mobile .contents .single a .name {
    font-size: 12px;
    font-weight: 500;
    color: #080707;
}

.dark-theme .all-feature-mobile {
    background-color: #1d3557;
}

.dark-theme .all-feature-mobile .title {
    border-bottom: 1px solid #ffffff21;
    color: #fff;
}

.dark-theme .all-feature-mobile .contents .single a {
    color: #fff;
}

.dark-theme .all-feature-mobile .contents .single a .name {
    color: #ffffffc7;
}


.mobile-transactions {
    background-color: #ededed;
}

.dark-theme .mobile-transactions {
    background-color: #1d3557;
}

.mobile-transactions .single-transaction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 10px;
    background: #dddddd;
    border-radius: 10px;
}

.dark-theme .mobile-transactions .single-transaction {
    background: #ffffff12;
}

.mobile-transactions .single-transaction:last-child {
    margin-bottom: 5px;
}

.mobile-transactions .single-transaction .transaction-left {
    width: 70%;
    font-size: 14px;
}

.mobile-transactions .single-transaction .transaction-left .transaction-title {
    color: #2c2929;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
}

.dark-theme .mobile-transactions .single-transaction .transaction-left .transaction-title {
    color: #c5c5c5;
}

.mobile-transactions .single-transaction .transaction-left .transaction-id {
    font-style: italic;
    color: #4158c5;
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 700;
}

.dark-theme .mobile-transactions .single-transaction .transaction-left .transaction-id {
    color: #dbeb54;
}

.mobile-transactions .single-transaction .transaction-left .transaction-type {
    color: #333333;
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 700;
}

.dark-theme .mobile-transactions .single-transaction .transaction-left .transaction-type {
    color: #74c0fd;
}

.mobile-transactions .single-transaction .transaction-left .transaction-date {
    color: #3c3535;
    font-size: 12px;
    margin-top: 5px;
}

.dark-theme .mobile-transactions .single-transaction .transaction-left .transaction-date {
    color: #c5c5c5;
}

.mobile-transactions .single-transaction .transaction-left .transaction-note {
    color: #333333;
    font-size: 12px;
    margin-top: 5px;
}

.dark-theme .mobile-transactions .single-transaction .transaction-left .transaction-note {
    color: #f7a43d;
}


.mobile-transactions .single-transaction .transaction-right {
    text-align: right;
    width: 30%;
    font-size: 14px;
}

.mobile-transactions .single-transaction .transaction-right .transaction-amount {
    font-size: 14px;
    margin-bottom: 3px;
    font-weight: 700;
}

.mobile-transactions .single-transaction .transaction-right .transaction-amount.add {
    color: #3b8f25;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-amount.add {
    color: #82df6a;
}

.mobile-transactions .single-transaction .transaction-right .transaction-amount.sub {
    color: #d52b2b;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-amount.sub {
    color: #d52b2b;
}

.mobile-transactions .single-transaction .transaction-right .transaction-fee {
    color: #82df6a;
    font-size: 12px;
    margin-bottom: 3px;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-fee {
    color: #82df6a;
}

.mobile-transactions .single-transaction .transaction-right .transaction-fee.add {
    color: #398525;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-fee.add {
    color: #82df6a;
}

.mobile-transactions .single-transaction .transaction-right .transaction-fee.sub {
    color: #d52b2b;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-fee.sub {
    color: #d52b2b;
}

.mobile-transactions .single-transaction .transaction-right .transaction-gateway {
    color: #2c2c2c;
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: 700;
    text-transform: uppercase;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-gateway {
    color: #c5c5c5;
}

.mobile-transactions .single-transaction .transaction-right .transaction-status {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: 700;
}

.mobile-transactions .single-transaction .transaction-right .transaction-status.success {
    color: #158d81;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-status.success {
    color: #36c7b9;
}

.mobile-transactions .single-transaction .transaction-right .transaction-status.pending {
    color: #897307;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-status.pending {
    color: #f7a43d;
}

.mobile-transactions .single-transaction .transaction-right .transaction-status.cancel {
    color: #d52b2b;
}

.dark-theme .mobile-transactions .single-transaction .transaction-right .transaction-status.cancel {
    color: #d52b2b;
}


/* Mobile Transaction Filter */
.mobile-transaction-filter {
    padding-bottom: 10px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
}

.mobile-transaction-filter::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}

.mobile-transaction-filter::-moz-scrollbar {
    height: 4px;
    width: 4px;
}

.mobile-transaction-filter .filter {
    align-items: center;
    display: flex;
}

.mobile-transaction-filter .filter select {
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 10px;
    border: 1px solid rgba(210, 211, 216, 0.7);
}

@media (max-width: 800px) {
    .mobile-transaction-filter .filter select {
        min-width: 80px;
    }
}

.mobile-transaction-filter .filter select option {
    font-weight: 500;
}

.mobile-transaction-filter .filter select:focus {
    box-shadow: none;
    border: 1px solid rgba(94, 63, 201, 0.5);
}

.mobile-transaction-filter .filter select.show {
    width: 100px;
}

.mobile-transaction-filter .filter .apply-btn {
    display: inline-block;
    background: #e73667;
    border-radius: 3px;
    padding: 4px 16px;
    border: none;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.mobile-transaction-filter .filter .apply-btn svg {
    width: 14px;
    position: relative;
    top: -1px;
}

.mobile-transaction-filter .filter .apply-btn:hover {
    background: #535a94;
}

.mobile-transaction-filter .filter .search {
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.mobile-transaction-filter .filter .search label {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #151d30;
    margin-right: 5px;
}

.dark-theme .mobile-transaction-filter .filter .search label {
    color: #ffffff;
}

.mobile-transaction-filter .filter .search input {
    height: 30px;
    border: 1px solid rgba(210, 211, 216, 0.7);
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    /* text-transform: uppercase; */
    border-radius: 3px;
    margin-right: 10px;
    /* min-width: 130px; */
    color: #333333;
    background: transparent;
}

.dark-theme .mobile-transaction-filter .filter .search input {
    border: 1px solid rgba(210, 211, 216, 0.7);
    color: #ffffff;
    background: transparent;
}

.mobile-transaction-filter .filter .search input:focus {
    box-shadow: none;
    border: 1px solid rgba(94, 63, 201, 0.5);
}

.dark-theme .mobile-transaction-filter .filter .search input:focus {
    box-shadow: none;
    border: 1px solid #e73667;
}

.mobile-transaction-filter .filter .filter-right-btn button {
    display: inline-block;
    margin-left: 20px;
    color: #101935;
    background: transparent;
    line-height: 1;
    transition: background-color 0.3s;
    margin-bottom: 5px;
}

.dark-theme .mobile-transaction-filter .filter .filter-right-btn button {
    color: #ffffff;
}

.mobile-transaction-filter .filter .filter-right-btn button svg {
    height: 18px;
}

.mobile-transaction-filter .filter .filter-right-btn .dropdown-menu {
    width: 200px;
    margin-top: 8px !important;
    padding: 20px;
}

.mobile-transaction-filter .filter .filter-right-btn .dropdown-menu .form-check {
    margin-bottom: 10px;
}

.mobile-transaction-filter .filter .filter-right-btn .dropdown-menu .form-check .form-check-input:checked {
    background-color: #5e3fc9;
    border-color: #5e3fc9;
}

.mobile-transaction-filter .filter .filter-right-btn .dropdown-menu .form-check .form-check-input:focus {
    box-shadow: none;
}

.mobile-transaction-filter .filter .filter-right-btn .dropdown-menu .form-check .form-check-label {
    font-size: 14px;
    font-weight: 700;
}

/* End Mobile Transaction Filter */


.mobile-ref-url .mobile-referral-link-form {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 7px;
}

.mobile-ref-url .mobile-referral-link-form {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 7px;
}

.mobile-ref-url .mobile-referral-link-form input {
    height: 45px;
    flex: 1 1 75%;
    background: transparent;
    border: 0px;
    padding-left: 0;
    font-size: 14px;
    color: #787878;
    border-bottom: 1px solid #E93F5C;
    font-style: italic;
}

.mobile-ref-url .mobile-referral-link-form button {
    height: 45px;
    flex: 1 1 25%;
    background: #e93f5c;
    border: 0px;
    border-radius: 0 4px 4px 0;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.mobile-ref-url .mobile-referral-link-form button svg {
    height: 18px;
}

.mobile-ref-url .referral-joined {
    font-size: 12px;
    margin-bottom: 0;
    color: #282828;
}

.dark-theme .mobile-ref-url .referral-joined {
    color: #fff;
}


.mob-shortcut-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.mob-shortcut-btn a {
    display: block;
    color: #001219;
    text-align: center;
    /* background: #ddcbf9; */
    border-radius: 10px;
    font-size: 14px;
    width: 100%;
    margin: 0 5px;
    padding: 15px 0;
}

.mob-shortcut-btn a:first-child {
    margin-left: 0;
    /* background: #c0ef6e; */
}

.mob-shortcut-btn a:last-child {
    margin-right: 0;
    /* background: #f9cea2; */
}

.mob-shortcut-btn a svg {
    display: block;
    height: 24px;
    margin: auto;
    margin-bottom: 5px;
}


.all-cards-mobile .contents.row [class*="col"]:nth-of-type(12n + 1) .single-card {
    background: #c0ef6e;
}

.all-cards-mobile .contents.row [class*="col"]:nth-of-type(12n + 2) .single-card {
    background: #f3d338;
}

.all-cards-mobile .contents.row [class*="col"]:nth-of-type(12n + 3) .single-card {
    background: #ddcbf9;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 1) .single-card {
    background: #f9cea2;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 2) .single-card {
    background: #ffc8dd;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 3) .single-card {
    background: #ccd5ae;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 4) .single-card {
    background: #e9ff70;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 5) .single-card {
    background: #ff8fab;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 6) .single-card {
    background: #f3ffb6;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 7) .single-card {
    background: #b9faf8;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 8) .single-card {
    background: #ffffff;
}

.all-cards-mobile .moretext-2 .contents.row [class*="col"]:nth-of-type(12n + 9) .single-card {
    background: #3dccc7;
}


/* Additional Notification Box css */
.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-dot {
    position: relative;
    background: transparent;
    color: #333333;
    margin: 0 10px;
}

.dark-theme .panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-dot {
    color: #fff;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-dot .bell-ringng {
    animation: shaking 2s ease infinite;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-dot .number {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ef476f;
    position: absolute;
    color: #ffffff;
    top: -2px;
    right: -5px;
    font-size: 10px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop {
    margin-top: 16px !important;
    width: 360px;
    border: 0px;
    box-shadow: 0px 0px 2px rgba(94, 63, 201, 0.4);
    border-radius: 4px;
    font-size: 14px;
    padding: 12px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .noti-head {
    background: #ffffff;
    padding: 12px;
    color: #151d30;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 0px 2px rgba(94, 63, 201, 0.4);
    margin-bottom: 5px;
    font-weight: 700;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .noti-head span {
    margin-left: 5px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ef476f;
    color: #ffffff;
    font-size: 10px;
    text-align: center;
    display: inline-block;
    line-height: 18px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti {
    max-height: 300px;
    overflow-y: scroll;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti::-webkit-scrollbar {
    width: 5px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #666666;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ffffff;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 10px;
    color: #151d30;
    background: #5e3fc90d;
    border-radius: 4px;
    font-weight: 700;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a:hover {
    background: #5e3fc91f;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a.read {
    background: #ffffff;
    font-weight: 400;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a.read:hover {
    background: #5e3fc91f;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a .icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a .icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a .icon svg {
    height: 18px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 1) a .icon {
    background: rgba(239, 71, 111, 0.1);
    color: #ef476f;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 2) a .icon {
    background: rgba(94, 63, 201, 0.1);
    color: #5e3fc9;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 3) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 4) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 5) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 6) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 7) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 8) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 9) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 10) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 11) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti:nth-child(12n + 12) a .icon {
    background: rgba(34, 124, 157, 0.1);
    color: #227c9d;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a .content {
    width: 80%;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a .content .main-cont {
    font-size: 14px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a .content .main-cont span {
    color: #227c9d
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .all-noti .single-noti a .content .time {
    font-size: 12px;
    color: #9b9b9b;
    margin-top: 3px;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .noti-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .noti-footer a.noti-btn-1 {
    display: inline-block;
    padding: 8px 18px;
    border-radius: 3px;
    background: #ef476f;
    color: #ffffff;
    font-weight: 700;
    font-size: 11px;
    text-transform: capitalize;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .noti-footer a.noti-btn-1:hover {
    background: #003049;
    color: #ffffff;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .noti-footer a.noti-btn-2 {
    display: inline-block;
    padding: 8px 18px;
    border-radius: 3px;
    background: linear-gradient(90deg, #e52e71, #ff8a00);
    color: #ffffff;
    font-weight: 700;
    font-size: 11px;
    text-transform: capitalize;
}

.panel-layout .panel-header .nav-wrap .nav-right .single-nav-right .notification-pop .noti-footer a.noti-btn-2:hover {
    background: #ef476f;
    color: #ffffff;
}


/* All notifications */
.notification-list {
    margin-bottom: 20px;
}

.notification-list .single-list {
    background: rgba(21, 29, 48, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    transition: 0.2s;
    border: 1px solid #66666624;
}

.dark-theme .notification-list .single-list {
    background: #ffffff0f;
    border: 1px solid #ffffff24;
}

@media (max-width: 575px) {
    .notification-list .single-list {
        display: block;
    }
}

.notification-list .single-list .cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
}

@media (max-width: 575px) {
    .notification-list .single-list .cont {
        width: 100%;
    }
}

.notification-list .single-list .cont .icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

@media (max-width: 575px) {
    .notification-list .single-list .cont .icon {
        margin-bottom: 15px;
    }
}

.notification-list .single-list .cont .icon svg {
    height: 18px;
}

.notification-list .single-list .cont .contents {
    font-weight: 700;
    font-size: 14px;
    width: 80%;
}

@media (max-width: 575px) {
    .notification-list .single-list .cont .contents {
        margin-bottom: 15px;
    }
}

.notification-list .single-list .cont .contents a {
    color: #ef476f;
}

.notification-list .single-list .cont .contents .time {
    color: #666666;
    font-size: 12px;
    margin-top: 5px;
}

.dark-theme .notification-list .single-list .cont .contents .time {
    color: #ffffff66;
}

.notification-list .single-list .link svg {
    height: 16px;
}

.notification-list .single-list:hover {
    background: rgba(21, 29, 48, 0.2);
}

.notification-list .single-list.read {
    background: rgba(21, 29, 48, 0.05);
}

.notification-list .single-list.read:hover {
    background: rgba(21, 29, 48, 0.2);
}

.notification-list .single-list:nth-of-type(12n + 1) .cont .icon {
    color: #227c9d;
    background: rgba(34, 124, 157, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 1):hover .icon {
    background: #227c9d;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 1).read .cont .icon {
    background: #227c9d;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 2) .cont .icon {
    color: #ef476f;
    background: rgba(239, 71, 111, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 2):hover .icon {
    background: #ef476f;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 2).read .cont .icon {
    background: #ef476f;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 3) .cont .icon {
    color: #003566;
    background: rgba(0, 53, 102, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 3):hover .icon {
    background: #003566;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 3).read .cont .icon {
    background: #003566;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 4) .cont .icon {
    color: #718355;
    background: rgba(113, 131, 85, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 4):hover .icon {
    background: #718355;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 4).read .cont .icon {
    background: #718355;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 5) .cont .icon {
    color: #ee6c4d;
    background: rgba(238, 108, 77, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 5):hover .icon {
    background: #ee6c4d;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 5).read .cont .icon {
    background: #ee6c4d;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 6) .cont .icon {
    color: #6d597a;
    background: rgba(109, 89, 122, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 6):hover .icon {
    background: #6d597a;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 6).read .cont .icon {
    background: #6d597a;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 7) .cont .icon {
    color: #3d405b;
    background: rgba(61, 64, 91, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 7):hover .icon {
    background: #3d405b;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 7).read .cont .icon {
    background: #3d405b;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 8) .cont .icon {
    color: #5e3fc9;
    background: rgba(94, 63, 201, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 8):hover .icon {
    background: #5e3fc9;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 8).read .cont .icon {
    background: #5e3fc9;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 9) .cont .icon {
    color: #ef476f;
    background: rgba(239, 71, 111, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 9):hover .icon {
    background: #ef476f;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 9).read .cont .icon {
    background: #ef476f;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 10) .cont .icon {
    color: #ef476f;
    background: rgba(239, 71, 111, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 10):hover .icon {
    background: #ef476f;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 10).read .cont .icon {
    background: #ef476f;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 11) .cont .icon {
    color: #227c9d;
    background: rgba(34, 124, 157, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 11):hover .icon {
    background: #227c9d;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 11).read .cont .icon {
    background: #227c9d;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 12) .cont .icon {
    color: #003566;
    background: rgba(0, 53, 102, 0.1);
}

.notification-list .single-list:nth-of-type(12n + 12):hover .icon {
    background: #003566;
    color: #ffffff;
}

.notification-list .single-list:nth-of-type(12n + 12).read .cont .icon {
    background: #003566;
    color: #ffffff;
}

.notification-list .single-list .link a {
    display: inline-block;
    font-size: 12px;
    padding: 5px 17px;
    border-radius: 50px;
}

.notification-list .single-list .link a svg {
    height: 13px;
    position: relative;
    top: -3px;
}

@media (max-width: 575px) {
    .notification-list .single-list .link {
        margin-left: 65px;
    }
}


/* Simple Notify Js */
.notify-live {
    font-family: inherit;
}

.notify-live.notify--success {
    box-shadow: none;
    border: 1px solid #5e3fc9;
}

.notify-live.notify--success .notify__icon {
    background: #5e3fc9;
    border-radius: 50%;
    color: #ffffff;
}

.notify-live.notify--success .notify__icon svg {
    height: 16px;
}

.notify-live.notify--success::before {
    background-color: #5e3fc9 !important;
}

.notify-live.notify--error {
    box-shadow: none;
    border: 1px solid #ef476f;
}

.notify-live.notify--error .notify__icon {
    background: #ef476f;
    border-radius: 50%;
    color: #ffffff;
}

.notify-live.notify--error .notify__icon svg {
    height: 16px;
}

.notify-live.notify--error::before {
    background-color: #ef476f !important;
}

.notify-live.notify--warning {
    box-shadow: none;
    border: 1px solid #ffc300;
}

.notify-live.notify--warning .notify__icon {
    background: #ffc300;
    border-radius: 50%;
    color: #ffffff;
}

.notify-live.notify--warning .notify__icon svg {
    height: 16px;
}

.notify-live.notify--warning::yellow {
    background-color: #5e3fc9 !important;
}

.notify-live.notify--info {
    box-shadow: none;
    border: 1px solid #2a9d8f;
}

.notify-live.notify--info .notify__icon {
    background: #2a9d8f;
    border-radius: 50%;
    color: #ffffff;
}

.notify-live.notify--info .notify__icon svg {
    height: 16px;
}

.notify-live.notify--info::before {
    background-color: #2a9d8f !important;
}

.notify-live .learn-more-link {
    position: absolute;
    bottom: 10px;
    left: 55px;
    font-size: 14px;
    color: #5e3fc9;
    font-weight: 700;
}

.notify-live .learn-more-link svg {
    height: 14px;
}

.notify-live .notify-content {
    margin-bottom: 25px;
}

/*# sourceMappingURL=styles.css.map */
